import './TableComponents.css';
import React, { useContext, useMemo } from 'react';
import { Column } from 'material-table';
import useScreenDimensions from '../../services/useScreenDimensions';
import { MenuContext } from '../../contexts/MenuContext';
import computeTableWidth from './computeTableWidth';

interface TableHeaderProps {
  columns: Partial<Column<any>>[];
}

function TableHeader({ columns }: TableHeaderProps) {
  const { width } = useScreenDimensions();
  const { isMenuOpen } = useContext(MenuContext);
  const rowWidth = useMemo(
    () => computeTableWidth(width, isMenuOpen),
    [width, isMenuOpen],
  );

  return (
    <div
      className="table-header"
      style={{
        width: rowWidth,
        gridTemplateColumns: `repeat(${columns.length},  calc(${rowWidth}px / ${columns.length}) )`,
      }}
    >
      {columns.map((column: any) => (
        <h3 key={`table-header-${column.title}`}>{column.title}</h3>
      ))}
    </div>
  );
}

export default TableHeader;
