export default {
  InvalidUserRolException: 'Invalid User Role.',
  RoleEntityException: 'Invalid User Role.',
  RoleIssuerException: 'Invalid User Role.',
  InvalidUuidFormatException: 'Invalid uuid format',
  InvalidISOFormatException: 'Invalid date format',
  InvalidEmailFormatException: 'Invalid email format',
  InvalidLevelOfAssuranceException: 'Level of Assurance not supported',
  InvalidFieldPathException: 'Invalid Field path',
  InvalidCredentialSubjectValue:
    'Credential subject property provided does not match with requested',
  InvalidCredentialSubjectProperty: 'Property not found in credential subject',
  DidNotFoundInJwt: 'DID not found in JWT',
  InternalServerCustomError: 'Internal Error',
  CredentialIdNotFoundException: 'Credential Order not found',
  CredentialOrderIdNotFoundException: 'Credential Order not found',
  CredentialOrdersByFilterNotFoundException:
    'There are no credentials that match your find',
  CredentialOrderNotFoundException: 'No credential order found',
  AuthServiceValidationException: 'Unauthorized Entity access',
  CredentialDuplicatedException:
    'The credential has already been issued previously',
  CredentialPayloadValidatorException:
    'Failed validating the credential payload',
  EntityDidNotValidException: 'Entity DID not valid',
  frontend_error_000: 'Error authenticating the User: ',
  frontend_error_001: 'Error creating the Credential Order: ',
  frontend_error_002: 'Error loading all credentials',
  frontend_error_003: 'Error issuing the Credential: ',
  frontend_error_004: 'Error getting the Credential Types: ',
  frontend_error_005: 'Error getting the Level Of Assurances: ',
  frontend_error_006: 'Error getting the Level Of Assurance type: ',
  frontend_error_007: 'Error getting Entities',
  frontend_error_008: 'Error resending the email',
  frontend_error_009: 'Error retrieving the credential request',
  errors: {
    getEntityDetails: 'The entity details could not be loaded. Try it again.',
    createEntity: 'The entity could not be created. Try it again.',
    editEntity: 'The entity could not be edited. Try it again.',
    getWalletDetails: 'The wallet details could not be loaded. Try it again.',
    createWallet: 'The wallet could not be created. Try it again.',
    deleteWallet: 'The wallet could not be deleted. Try it again.',
    editWalletAlias: 'The wallet alias could not be edited. Try it again.',
    getKeyStorages: 'The key storages could not be loaded. Try it again.',
    didDocument: 'The DID document could not be loaded. Try it again.',
    createDid: 'The DID could not be created. Try it again.',
    editDidAlias: 'The DID alias could not be edited. Try it again.',
    deleteDid: 'The DID could not be deleted. Try it again.',
    activateDid: 'The DID could not be activated. Try it again.',
    invalidUrl: 'Invalid URL',
  },
  successes: {
    createEntity: 'The entity has been successfully created.',
    editEntity: 'The entity has been successfully edited.',
    createWallet: 'The wallet has been successfully created.',
    deleteWallet: 'The wallet has been successfully deleted.',
    editWalletAlias: 'The wallet alias has been successfully edited.',
    createDid: 'The DID has been successfully created.',
    editDidAlias: 'The DID alias has been successfully edited.',
    deleteDid: 'The DID has been successfully deleted.',
    activateDid:
      'The DID has been successfully activated. You will shortly see the changes.',
  },
  login: {
    control:
      'Control and issue verifiable credentials at any time and from anywhere',
    with_vidchain:
      "With VIDidentity we're offering secure identity solutions that allow a more digital and sustainable world guaranteeing privacy, rights, and freedoms to people.",
    login_with: 'Login with VIDwallet',
    more_info: 'More info about VIDwallet',
    successful: 'The login was successful',
    failed: 'The login process failed',
    redirected: 'You will be redirected to the home screen.',
    try_again: 'Please try again or contact the admin of your site.',
    continueIssuance:
      'Click on the login button to continue with the issuance credential process',
    redirecting: 'Redirecting for authentication…',
    get_it: 'Get it free on your phone',
  },
  callback: {
    success: 'The credential issuance process was successful',
    mobile_wallet:
      'Credential issued. Open VIDwallet to receive the credential',
    go_wallet: 'Open VIDwallet to check the credential.',
    open_wallet: 'Open VIDwallet',
    failed: 'The credential issuance process failed',
    error_details: 'Error Details:',
  },
  disclaimers: {
    logout: 'You are about to log out.\n Are you sure?',
  },
  actions: {
    cancel: 'Cancel',
    filter: 'Filter',
    accept: 'Accept',
    success: 'Success',
    confirm: 'Confirm',
    search: 'Search',
    create: 'Create',
    revoke: 'Revoke',
    resend: 'Resend',
    edit: 'Edit',
    close: 'Close',
    save: 'Save',
    approve: 'Approve',
    reject: 'Reject',
    sendToSign: 'Send to sign',
    ok: 'OK',
    back: 'Back',
    delete: 'Delete',
    logout: 'Log out',
    copy: 'Copy',
    copied: 'Copied',
    download: 'Download',
    continue: 'Continue',
  },
  form: {
    fill: 'Fill in the form',
    receiverEmail: 'Email of the Receiver',
    identityVerification: 'Identity verification method',
    loadingCredential: 'Loading Credential type data ...',
    loadingIdentity: 'Loading Identity verification data ...',
    createNewUser: 'Create new user',
    fillfield: 'You must fill this field',
    provideValidEmail: 'You must provide a valid email',
    provideValidNumber: 'You must provide a valid personal number',
    provideValidURL: 'You must provide a valid URL',
    provideValidDate: 'You must provide a valid date',
    credentialCreated:
      'Credential order created successfully. The user will receive an email to get the credential',
    credentialF2FCreated:
      'Credential order created successfully. Please sign the document in device %%%%% to finish the process',
    credentialSubject: 'Credential Subject',
    language: 'Language',
    chooseLanguage: 'Choose a language...',
    credentialUpdated: 'Credential request updated successfully',
    userLanguage: 'User Language',
  },
  language: {
    spanish: 'Spanish',
    english: 'English',
  },
  filters: {
    title: 'Filters',
    any: 'Any attribute in the credential',
    credentialType: 'Credential Type',
    startDate: 'Start date',
    endDate: 'End date',
    actionName: 'Action Name',
    entityName: 'Entity Name',
    selectEntity: 'Select an entity',
    searchText: 'Search text',
    types: 'Type',
    status: 'Status',
  },
  table: {
    creationDate: 'Creation Date',
    notAvailable: 'N/A',
    empty: 'No records to display',
    sureRevoke: 'Are you sure you want to revoke this credential?',
    sureReject: 'Are you sure you want to reject this credential request?',
    agentName: 'Agent Name',
    date: 'Date',
    status: 'Status',
    viewDetails: 'View details',
    revokeCredential: 'Revoke credential',
    resendEmail: 'Resend email',
    ivmStatus: 'Identity Verification Method Status',
    expirationDate: 'Expiration date',
    rowsPerPage: 'Rows per page:',
    of: 'of',
    more: 'more than',
  },
  menu: {
    credentials: 'Credentials management',
    issueCredentials: 'Issue credentials',
    user: 'User management',
    auditTrails: 'Audit trails',
    credentialRequests: 'Credential requests',
    entitiesManagement: 'Entities management',
  },
  details: {
    attachments: 'Attachments',
    auditTrailsDetails: 'Audit trails details',
    name: 'Name',
    eventName: 'Event name',
    operatorName: 'Agent name',
    entity: 'Entity',
    operator: 'Agent',
    role: 'Role',
    legalId: 'Legal ID',
    action: 'Action',
    payload: 'Payload',
    emailResentSuccess: 'Email has been resent successfully',
    revokeSuccess: 'Revocation request succeeded',
    rejectSuccess: 'Credential request rejected',
    credentialDetails: 'Credential Details',
    userDetails: 'User Details',
    requestDetails: 'Request Details',
    credentialRequestDetails: 'Credential Request',
    credentialOrderId: 'Credential Order ID',
    credentialIssuanceId: 'Credential Issuance ID',
    type: 'Credential type',
    types: 'Type',
    issuer: 'Issuer',
    holder: 'Holder',
    createdAt: 'Created At',
    issuedAt: 'Issued At',
    id: 'ID',
    test: 'Test',
    receiverEmail: 'Email of the receiver',
    identityVerificationMethod: 'Identity verification method',
    faceToFace: 'Face-to-face',
    next: 'Next',
    previous: 'Previous',
    stillNotSigned:
      'The document is still not signed. Sign the document in the device and then click Next button.',
    credentialIssuanceDocumentSignature:
      'Credential issuance document signature',
    selectDevice: 'Select a Device where to send the document to sign:',
    signedDocument: 'An email has been sent to the user to finish the process',
    docHasBeenSent:
      'Sign the document in the device and then click Next button.',
    docHasBeenRejected: 'Document Rejected',
    sent: 'Document sent',
    signed: 'Document signed',
    credentialOrderCreatedFromRequest:
      'Your credential request is approved. Go to credentials management.',
    credentialRequestApproved: 'Credential request approved',
  },
  requestDetails: {
    credentialRequestId: 'Credential Request ID',
    type: 'Credential type',
    receiverEmail: 'Email of the receiver',
    requestedAt: 'Requested At',
    identityVerificationMethod: 'Identity verification method',
    F2F: 'Face-to-face',
    userDetails: 'User Details',
    EmailCredential: 'Email Credential',
    VidKycCredential: 'KYC',
  },
  identityVerificationMethod: {
    userName: 'Full name',
    userLegalId: 'Legal ID',
  },
  status: {
    CREATED: 'Created',
    ISSUED: 'Issued',
    REVOKED: 'Revoked',
    REVOCATION_REQUESTED: 'Revoked requested',
    undefined: 'Undefined',
    REQUESTED: 'Requested',
    VERIFIED: 'Verified',
    UNVERIFIED: 'Unverified',
    ISSUE_REQUESTED: 'Issue Requested',
    REVOCATION_FAILED: 'Revocation failed',
  },
  requestStatus: {
    REQUESTED: 'Requested',
    APPROVED: 'Approved',
  },
  identityVerificationStatus: {
    CREATED: 'Created',
    STARTED: 'Started',
    DOCUMENT_REJECTED: 'Document rejected',
    DOCUMENT_SENT: 'Document sent',
    FINISHED: 'Finished',
  },
  auditTrailsActions: {
    UserLogin: 'User login',
    CredentialOrderCreated: 'Credential order created',
    ResendCredentialOrderEmail: 'Resend credential order email',
    CredentialHolderAuthenticated: 'Credential holder authenticated',
    CredentialIssued: 'Credential issued',
    CredentialRevocationRequested: 'Credential revocation requested',
    CredentialRevoked: 'Credential revoked',
    CredentialRequestCreated: 'Credential order request created',
    CredentialOrderRequestCreated: 'Credential order request created',
    CredentialRequestConfirmed: 'Credential request confirmed',
    CredentialRequestDocumentSigned: 'Credential request document signed',
    CredentialOrderRequestDeleted: 'Credential order request deleted',
    PresentationCreated: 'Presentation created',
  },
  headers: {
    credentials: 'Credentials management',
    issueCredentials: 'Issue credentials',
    userCredentials: 'User management',
    auditTrails: 'Audit trails',
    credentialRequests: 'Credential requests',
    entitiesManagement: 'Entities management',
    createEntity: 'Create entity',
    entityDetails: 'Entity details',
    createWallet: 'Create wallet',
    walletDetails: 'Wallet details',
    createDid: 'Create DID',
    new: 'New',
  },
  entitiesManagementPage: {
    entitiesManagementSection: {
      title: 'Entities',
      buttonTitle: 'Create entity',
      emptyListMessage: 'No registered entity.',
      columns: {
        alias: 'Alias',
        cif: 'VAT/Identifier',
      },
      editEntity: 'Edit entity',
    },
    createEntity: {
      name: 'Entity name',
      cif: 'VAT/Identifier',
    },
    entityDetails: {
      title: "{{entityName}}'s wallets list",
      emptyListMessage: 'No registered wallet.',
      buttonTitle: 'Create wallet',
      columns: {
        alias: 'Alias',
        dids: 'DIDs',
      },
      deleteWallet: {
        title: 'Delete wallet',
        disclaimer:
          'You are about to delete this wallet.\nAre you sure you want to delete it?',
      },
      editWalletAlias: 'Edit alias',
    },
    createWallet: {
      alias: 'Wallet alias',
      keyStorageDescription: 'Choose how to store your keys',
      summary: 'Wallet summary',
      steps: {
        first: 'Set an alias',
        second: 'Select a key storage',
      },
    },
    walletDetails: {
      title: "{{walletName}}'s DIDs list",
      emptyListMessage: 'No registered DIDs.',
      buttonTitle: 'Create DID',
      validateUrl: 'Validate URL',
      didDetails: 'DID details',
      columns: {
        alias: 'Alias',
        method: 'DID method',
        value: 'Value',
      },
      deleteDid: {
        title: 'Delete DID',
        disclaimer:
          'You are about to delete this DID.\nAre you sure you want to delete it?',
      },
      editDidAlias: 'Edit alias',
    },
    createDid: {
      alias: 'DID alias',
      method: 'DID method',
      didWebDescription:
        'It is necessary to provide a URL that has the DID document in it in order to generate a did:web',
      didWebMessage:
        'The DID will not be generated until the provided URL is validated.\nDownload the DID document and upload it to the URL to complete the process.',
      success:
        'The DID has been successfully created. It may not appear immediately, as the process can take a few minutes.',
      steps: {
        first: 'Set an alias',
        second: 'Select a DID',
      },
    },
  },
};
