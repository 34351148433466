import './Summary.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ThemedInput from '../ThemedInput/ThemedInput';
import ThemedButton from '../Buttons/ThemedButton';

interface SummaryProps {
  summaryTitle: string;
  onCreate: () => Promise<void>;
  onClickBack: () => void;
  entries: { value: string; label: string }[];
}

function Summary({
  summaryTitle,
  onCreate,
  onClickBack,
  entries,
}: SummaryProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <section className="summary-container">
      <h2>{summaryTitle}</h2>
      <section className="summary-container_middle-section">
        {entries.map(({ value, label }) => (
          <ThemedInput
            key={`summary-value-${label}`}
            type="text"
            value={value}
            name={label}
            inputOptions={{ readOnly: true, label }}
          />
        ))}
      </section>
      <section className="summary-container_bottom-section">
        <ThemedButton
          text={t('actions.back')}
          onClick={() => onClickBack()}
          background="gray"
        />
        <ThemedButton
          text={t('actions.create')}
          onClick={async () => {
            setIsLoading(true);
            await onCreate();
            setIsLoading(false);
          }}
          isLoading={isLoading}
        />
      </section>
    </section>
  );
}

export default Summary;
