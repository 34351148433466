import React, { useMemo, useState } from 'react';
import { Did } from '../../../domain/entitiesManagementDtos';
import ThemedButton from '../../../components/Buttons/ThemedButton';
import Banner from '../../../components/Banner/Banner';
import ActionsCell, {
  Action,
} from '../../../components/TableComponents/ActionsCell';
import EyeIcon from '../../../assets/icons/table-icons/eye.svg';
import PencilIcon from '../../../assets/icons/table-icons/pencil.svg';
import DeleteIcon from '../../../assets/icons/table-icons/delete.svg';
import { useTranslation } from 'react-i18next';
import { useEntitiesContext } from '../../../contexts/EntitiesContext';
import { useParams } from 'react-router-dom';
import ToolTipItem from '../../../components/ToolTipItem/ToolTipItem';
import { v4 } from 'uuid';

const TRANSLATION_PREFIX = 'entitiesManagementPage.walletDetails.';

interface UseListColumnsProps {
  onOpenDidDetailsModal: (did: string) => Promise<void>;
  setDidToEdit: React.Dispatch<
    React.SetStateAction<{ alias: string; did: string; walletId: string }>
  >;
  setDidToDelete: React.Dispatch<
    React.SetStateAction<{ did: string; walletId: string }>
  >;
  setIsEditDidAliasModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeleteDidModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

function useListColumns({
  onOpenDidDetailsModal,
  setDidToEdit,
  setDidToDelete,
  setIsEditDidAliasModalOpened,
  setIsDeleteDidModalOpened,
}: UseListColumnsProps) {
  const { didRepo, getWallets } = useEntitiesContext();
  const { t } = useTranslation();
  const { entityId, walletId } = useParams();

  return [
    { field: 'alias', title: t(`${TRANSLATION_PREFIX}columns.alias`) },
    {
      field: 'didMethod',
      title: t(`${TRANSLATION_PREFIX}columns.method`),
    },
    {
      field: 'did',
      title: t(`${TRANSLATION_PREFIX}columns.value`),
      render: (did: Did) => {
        const [isLoading, setIsLoading] = useState<boolean>(false);
        const id = useMemo(() => v4(), []);
        async function onActivateDid(did: string) {
          setIsLoading(true);
          const response = await didRepo.activateDid(walletId ?? '', did);
          if ('error' in response) {
            Banner.show(t('errors.activateDid'), true);
            setIsLoading(false);
            return;
          }
          Banner.show(t('successes.activateDid'));
          await getWallets(entityId ?? '', walletId ?? '');
          setIsLoading(false);
        }

        return did.isActive ? (
          <ToolTipItem id={id} title={did.did} canCopy>
            <h3>{did.did}</h3>
          </ToolTipItem>
        ) : (
          <ThemedButton
            key={`validate-did-${did.alias.replaceAll('s', '')}-${did.url}`}
            text={t(`${TRANSLATION_PREFIX}validateUrl`)}
            isLoading={isLoading}
            onClick={() => onActivateDid(did.did)}
            size="small"
            background="lightGreen"
          />
        );
      },
    },
    { field: 'url', title: 'URL' },
    {
      field: '',
      title: '',
      render: (did: Did) => {
        const labelKey = did.alias.match(/[a-zA-Z]+/g)?.join('');
        const actions: Action[] = [
          {
            onClick: () => onOpenDidDetailsModal(did.did),
            labelId: `view-${labelKey}`,
            labelTitle: t('table.viewDetails'),
            icon: EyeIcon,
          },
          {
            onClick: () => {
              setDidToEdit({
                alias: did.alias,
                did: did.did,
                walletId: walletId ?? '',
              });
              setIsEditDidAliasModalOpened(true);
            },
            labelId: `edit-${labelKey}`,
            labelTitle: t('actions.edit'),
            icon: PencilIcon,
          },
          {
            onClick: () => {
              setDidToDelete({
                did: did.did,
                walletId: walletId ?? '',
              });
              setIsDeleteDidModalOpened(true);
            },
            labelId: `delete-${labelKey}`,
            labelTitle: t('actions.delete'),
            icon: DeleteIcon,
          },
        ];
        return <ActionsCell key={`did-actions-${did.did}`} actions={actions} />;
      },
    },
  ];
}

export default useListColumns;
