import spinner from '../../assets/spinners/spinner.gif';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getCredentialOfferQr,
  getIssuanceToken,
} from '../../services/vidcredentials';
import useSocketConnection from '../../services/openIDSocket';

function CredentialOrder() {
  const { t } = useTranslation();
  const [qrCode, setQrCode] = useState('');
  const [issuanceSuccess, setIssuanceSuccess] = useState(false);
  const [issuanceFailed, setIssuanceFailed] = useState(false);
  const { socket, socketId } = useSocketConnection();

  const emailId = new URLSearchParams(window.location.search).get('emailId');
  if (!emailId) {
    console.log('Error: emailId not found');
  }

  useEffect(() => {
    socket.on('status', (status: string) => {
      if (status === 'Success') setIssuanceSuccess(true);
      if (status === 'Failed') setIssuanceFailed(true);
    });
    return () => {
      socket.off('status');
    };
  }, [socket]);

  const loadCredentialOffer = async () => {
    if (!emailId) {
      console.log('Error credential request id not found');
    }

    const access_token = await getIssuanceToken(emailId as string, socketId);
    if (!access_token) {
      console.log('Failed to get token');
      setIssuanceSuccess(false);
    }

    const qrBase64 = await getCredentialOfferQr(access_token as string);
    if (!qrBase64) {
      console.log('Failed to get QR Code');
      setIssuanceSuccess(false);
      return;
    }
    setQrCode(qrBase64 as string);
  };

  useEffect(() => {
    if (socketId && !qrCode) {
      loadCredentialOffer();
    }
  }, [socketId]);

  return (
    <div className="blue-container">
      {!issuanceSuccess && !issuanceFailed ? (
        <div>
          {!qrCode && <img src={spinner} alt="Spinner" />}
          <h3>{t('login.redirecting')}</h3>
          {qrCode && <img src={qrCode} alt="QR Code" />}
          <h3> Waiting for Issuance result... </h3>
        </div>
      ) : issuanceSuccess ? (
        <h3>Issuance Success</h3>
      ) : (
        <h3>Issuance Failed</h3>
      )}
    </div>
  );
}

export default CredentialOrder;
