import './SharedEntitiesManagementStyles.css';
import React, { useMemo, useState } from 'react';
import BackButton from '../../components/Buttons/BackButton';
import { FormBuilder } from '../../domain/FormBuilder/FormBuilder';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEntitiesContext } from '../../contexts/EntitiesContext';
import Banner from '../../components/Banner/Banner';
import { Entity } from '../../domain/entitiesManagementDtos';
import { FRONTEND_PATHS } from '../../config';

function CreateEntity() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [entityValues, setEntityValues] = useState<Omit<Entity, 'id'>>({
    name: '',
    vatNumber: '',
  });
  const { t } = useTranslation();
  const { entityRepo } = useEntitiesContext();

  const handleChange = (event: { target: HTMLInputElement }) => {
    const { name, value } = event.target;
    setEntityValues({
      ...entityValues,
      [name]: value.trimStart(),
    });
  };

  async function handleClick() {
    setIsLoading(true);
    const { name, vatNumber } = entityValues;
    const response = await entityRepo.createEntity(name, vatNumber);
    setIsLoading(false);
    if ('error' in response) {
      Banner.show(t('errors.createEntity'), true);
      return;
    }
    Banner.show(t('successes.createEntity'));
    navigate('/entities-management', {
      state: { entityId: response.entityId },
    });
  }

  const formJSX = useMemo(() => {
    const form = new FormBuilder();
    form.addInput('text', entityValues.name, 'name', handleChange, {
      label: t('entitiesManagementPage.createEntity.name'),
    });
    form.addInput('text', entityValues.vatNumber, 'vatNumber', handleChange, {
      label: t('entitiesManagementPage.createEntity.cif'),
    });
    form.addButton(t('actions.create'), handleClick, {
      isLoading,
      isDisabled: !entityValues.name || !entityValues.vatNumber,
    });
    return form.getJSX();
  }, [entityValues, t, isLoading]);

  return (
    <section
      className="main-container"
      style={{ alignItems: 'center', justifyContent: 'center' }}
    >
      <BackButton destinationRoute={FRONTEND_PATHS.ENTITIES_MANAGEMENT} />
      {formJSX}
    </section>
  );
}

export default CreateEntity;
