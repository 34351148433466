import '../SharedEntitiesManagementStyles.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGenerateModal } from '../../../components/Modal/Modal';
import ThemedButton from '../../../components/Buttons/ThemedButton';
import { useEntitiesContext } from '../../../contexts/EntitiesContext';
import Banner from '../../../components/Banner/Banner';

const TRANSLATION_PREFIX = 'entitiesManagementPage.walletDetails.deleteDid.';

function useDeleteDid() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [didToDelete, setDidToDelete] = useState<{
    did: string;
    walletId: string;
  }>({ did: '', walletId: '' });
  const { didRepo, selectedWallet, setSelectedWallet } = useEntitiesContext();
  const { t } = useTranslation();
  const { setIsDeleteDidModalOpened, DeleteDidModal } =
    useGenerateModal<'DeleteDid'>('DeleteDid');

  async function handleClick() {
    if (!selectedWallet) {
      return;
    }
    setIsLoading(true);
    const response = await didRepo.deleteDid(
      didToDelete.walletId,
      didToDelete.did,
    );
    setIsDeleteDidModalOpened(false);
    setIsLoading(false);
    if ('error' in response) {
      Banner.show(t('errors.deleteDid'), true);
      return;
    }
    const didsListCopy = [...selectedWallet?.dids].filter(
      (did) => did.did !== didToDelete.did,
    );
    setSelectedWallet({ ...selectedWallet, dids: didsListCopy });
    Banner.show(t('successes.deleteDid'));
  }

  return {
    setIsDeleteDidModalOpened,
    setDidToDelete,
    deleteModalJSX: (
      <DeleteDidModal title={t(`${TRANSLATION_PREFIX}title`)}>
        <p className="main-container_modal__paragraph">
          {t(`${TRANSLATION_PREFIX}disclaimer`)}
        </p>
        <section className="main-container_modal__buttons-section">
          <ThemedButton
            text={t('actions.cancel')}
            onClick={() => setIsDeleteDidModalOpened(false)}
            background="gray"
          />
          <ThemedButton
            text={t('actions.delete')}
            onClick={handleClick}
            isLoading={isLoading}
          />
        </section>
      </DeleteDidModal>
    ),
  };
}

export default useDeleteDid;
