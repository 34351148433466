import React from 'react';
import {
  CreateDidRequestBody,
  URL_REGEX,
} from '../../domain/entitiesManagementDtos';
import { useTranslation } from 'react-i18next';
import { SelectInputOption } from '../../domain/FormBuilder/FormBuilder';
import ThemedInput from '../ThemedInput/ThemedInput';

interface SecondStepProps {
  method: string;
  setDid: React.Dispatch<React.SetStateAction<CreateDidRequestBody>>;
  url?: string;
}

const didMethodOptions: SelectInputOption[] = [
  { name: 'did:key', value: 'did:key' },
  { name: 'did:web', value: 'did:web' },
  { name: 'did:ebsi', value: 'did:ebsi' },
];

function SecondStep({ method, url, setDid }: SecondStepProps) {
  const { t } = useTranslation();
  return (
    <>
      <ThemedInput
        type="select"
        value={method}
        name="method"
        inputOptions={{
          label: t('entitiesManagementPage.createDid.steps.second'),
          selectInputOptions: didMethodOptions,
          onSelectOption: (option) => {
            const opts = option.value === 'did:web' ? { url: '' } : undefined;
            setDid((prevState) => ({
              ...prevState,
              method: option.value,
              opts,
            }));
          },
        }}
      />
      {url !== undefined && (
        <ThemedInput
          type="url"
          value={url}
          name="url"
          errorMessage={
            !URL_REGEX.test(url) ? t('errors.invalidUrl') : undefined
          }
          onChange={(e) =>
            setDid((prevState) => ({
              ...prevState,
              opts: { url: e.target.value },
            }))
          }
          inputOptions={{
            label: 'URL',
            description: t(
              'entitiesManagementPage.createDid.didWebDescription',
            ),
          }}
        />
      )}
    </>
  );
}

export default SecondStep;
