import './LateralMenu.css';
import React, { useContext } from 'react';
import VIDidentityLogo from '../../assets/vidcredentials-logo.svg';
import VIDBadge from '../../assets/validatedid-badge.svg';
import CredentialManagementIcon from '../../assets/icons/navigation-icons/credential-management.svg';
import CredentialRequestsIcon from '../../assets/icons/navigation-icons/credential-requests.svg';
import IssueCredentialsIcon from '../../assets/icons/navigation-icons/issue-credential.svg';
import UserCredentialsIcon from '../../assets/icons/navigation-icons/user-credentials.svg';
import AuditTrailsIcon from '../../assets/icons/navigation-icons/audit-trails.svg';
import EntitiesManagementIcon from '../../assets/icons/navigation-icons/entities-management.svg';
import ArrowIcon from '../../assets/icons/navigation-icons/arrow.svg';
import { MenuContext } from '../../contexts/MenuContext';
import { AuthContext } from '../../contexts/AuthContext';
import LateralMenuLink from './LateralMenuLink/LateralMenuLink';

export const LateralMenu = () => {
  const { isMenuOpen, setIsOpen } = useContext(MenuContext);
  const { isSuperAdmin, isAdmin, isOperator } = useContext(AuthContext);

  return (
    <nav
      className="lateral-menu-container"
      style={{ width: isMenuOpen ? '350px' : '100px' }}
    >
      <section className="lateral-menu_logo-wrapper">
        <img
          src={isMenuOpen ? VIDidentityLogo : VIDBadge}
          className="lateral-menu_logo"
          alt="VIDidentity logo"
        />
      </section>
      <section className="lateral-menu_navigator" data-testid="links-container">
        <LateralMenuLink
          translationKey="credentials"
          sectionPath="/credentials"
          icon={CredentialManagementIcon}
        />
        {(isOperator || isAdmin) && (
          <>
            <LateralMenuLink
              translationKey="issueCredentials"
              sectionPath="/issue-credentials"
              icon={IssueCredentialsIcon}
            />
            <LateralMenuLink
              translationKey="credentialRequests"
              sectionPath="/credential-requests"
              icon={CredentialRequestsIcon}
            />
          </>
        )}
        {(isSuperAdmin || isAdmin) && (
          <>
            <LateralMenuLink
              translationKey="user"
              sectionPath="/user-credentials"
              icon={UserCredentialsIcon}
            />
            <LateralMenuLink
              translationKey="auditTrails"
              sectionPath="/audit-trails"
              icon={AuditTrailsIcon}
            />
          </>
        )}
        {isSuperAdmin && (
          <LateralMenuLink
            translationKey="entitiesManagement"
            sectionPath="/entities-management"
            icon={EntitiesManagementIcon}
            isNew
          />
        )}
      </section>
      <button
        className="lateral-menu_expandable-button"
        onClick={() => setIsOpen(!isMenuOpen)}
      >
        <img
          src={ArrowIcon}
          alt="Expand/Collapse icon"
          style={{ rotate: `${isMenuOpen ? '0' : '180'}deg` }}
        />
      </button>
    </nav>
  );
};
