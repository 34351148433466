import { decodeJWT } from 'did-jwt';
import { JWTDecoded } from 'did-jwt/lib/JWT';

export class UserAuth {
  private readonly decodedToken: JWTDecoded | undefined;
  constructor(private accessToken: string) {
    try {
      this.decodedToken = decodeJWT(this.accessToken);
    } catch (e) {
      localStorage.removeItem('accessToken');
    }
  }

  getUserName(): string {
    return this.decodedToken
      ? this.decodedToken.payload.userInfo.name.toString()
      : '';
  }

  getEntityName(): string {
    return this.decodedToken
      ? this.decodedToken.payload.userInfo.entityName.toString()
      : '';
  }
  getRole(): string {
    return this.decodedToken
      ? this.decodedToken.payload.userInfo.role.toString()
      : '';
  }
  isAdmin(): boolean {
    return (
      !!this.decodedToken &&
      this.decodedToken.payload.userInfo.role.toString() === 'admin'
    );
  }
  isSuperadmin(): boolean {
    return (
      !!this.decodedToken &&
      this.decodedToken.payload.userInfo.role.toString() === 'superadmin'
    );
  }
  isOperator(): boolean {
    return (
      !!this.decodedToken &&
      this.decodedToken.payload.userInfo.role.toString() === 'operator'
    );
  }
}
