import axiosInstance from './axiosInstance';
import { BACKEND_URL, PATHS } from '../config';
import { Entity, KeyStorageInfo } from '../domain/entitiesManagementDtos';
import { v4 as uuidv4 } from 'uuid';

class EntityRepository {
  async createEntity(
    name: string,
    vatNumber: string,
  ): Promise<{ entityId: string } | { error: string }> {
    const nameSlug = name.toLowerCase().split(' ').join('-');
    const entityId = uuidv4();
    const body = {
      name,
      vatNumber,
      nameSlug,
      entityId,
      icon: '-',
      callbackUrl: 'https://www.example.com',
      redirectUrls: ['https://www.example.com'],
      scope: '-',
    };
    const response = await axiosInstance.post(
      `${BACKEND_URL}${PATHS.ENTITIES}`,
      body,
    );
    if (response.status >= 400) {
      return { error: response.statusText };
    }
    return { entityId };
  }

  async editEntity(
    entity: Entity,
  ): Promise<{ status: string } | { error: string }> {
    const { id, ...details } = entity;
    const response = await axiosInstance.patch(
      `${BACKEND_URL}${PATHS.ENTITIES}/${entity.id}`,
      details,
    );
    if (response.status >= 400) {
      return { error: response.statusText };
    }
    return { status: 'OK' };
  }

  async getEntities(): Promise<{ data: Entity[] } | { error: string }> {
    const response = await axiosInstance.get(`${BACKEND_URL}${PATHS.ENTITIES}`);
    if (response.status >= 400) {
      return { error: response.statusText };
    }
    return { data: response.data };
  }

  async getKeyStorages(): Promise<
    { data: KeyStorageInfo[] } | { error: string }
  > {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.KEY_MANAGEMENT_SERVICE}`,
    );
    if (response.status >= 400) {
      return { error: response.statusText };
    }
    return { data: response.data };
  }
}

export default EntityRepository;
