import './TableComponents.css';
import React from 'react';
import TooltipItem from '../ToolTipItem/ToolTipItem';

export interface Action {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon: string;
  labelTitle: string;
  labelId: string;
  isDisabled?: boolean;
}

function ActionsCell({ actions }: { actions: Action[] }) {
  return (
    <div className="actions-container">
      {actions.map(({ onClick, labelId, isDisabled, labelTitle, icon }) => (
        <button
          key={`action-cell-${labelId}`}
          onClick={onClick}
          disabled={isDisabled}
          className="actions-container_button"
        >
          <TooltipItem id={labelId} title={labelTitle}>
            <img src={icon} alt={labelTitle} />
          </TooltipItem>
        </button>
      ))}
    </div>
  );
}

export default ActionsCell;
