import '../Shared.css';
import React, { useContext, useState } from 'react';
import { UserCredentialsTable } from './UserCredentialsTable/UserCredentialsTable';
import { IssueUserCredentialForm } from '../../components/IssueUserCredentialForm/IssueUserCredentialForm';
import { useRouteSecurization } from '../../hooks/useRouteSecurization';
import {
  UserCredentialsContext,
  UserCredentialsFilters,
} from '../../contexts/CredentialsProviders/Providers/UserCredentialsContext';
import FilterTag from '../../components/FilterTag/FilterTag';
import { convertToQueryString } from '../Credentials/CredentialDetailsModal/helpers/StringHelpers';
import TableActions from '../../components/TableComponents/TableActions';
import { useTranslation } from 'react-i18next';
import ThemedButton from '../../components/Buttons/ThemedButton';
import PlusIcon from '../../assets/icons/plus.svg';

export function UserCredentials() {
  useRouteSecurization(['admin', 'superadmin']);
  const { t } = useTranslation();
  const TAGS_NAMES: Record<string, any> = {
    entityId: t('details.entity'),
    entityName: t('details.entity'),
    email: 'Email',
    did: 'DID',
    searchableText: t('filters.searchText'),
    status: t('table.status'),
  };
  const { filters, setFilters, tableSpecs, loadCredentials } = useContext(
    UserCredentialsContext,
  );
  const [isIssueUserCredentialFormOpen, setIssueUserCredentialFormOpen] =
    useState(false);

  const onRemoveTag = async (filterKey: keyof UserCredentialsFilters) => {
    const updatedFilter = {
      ...filters,
      [filterKey]: '',
      entityId: filterKey === 'entityName' ? '' : filters.entityId,
    };
    const queryParams = convertToQueryString(updatedFilter);
    setFilters(updatedFilter);
    await loadCredentials(tableSpecs.tableSize, 1, queryParams);
  };

  return (
    <div className="credentials-container">
      <IssueUserCredentialForm
        isIssueUserCredentialOpen={isIssueUserCredentialFormOpen}
        setIsIssueUserCredentialOpen={setIssueUserCredentialFormOpen}
        loadTable={loadCredentials}
        currentPage={tableSpecs.currentPage}
        tableSize={tableSpecs.tableSize}
        queryParams={convertToQueryString(filters)}
      />
      <TableActions context={UserCredentialsContext} />
      <section className="credentials-container_create-button-wrapper">
        <ThemedButton
          text={t('actions.create')}
          onClick={() => setIssueUserCredentialFormOpen(true)}
          icon={PlusIcon}
          background="white"
          size="big"
        />
      </section>
      <div className="filter-list-container">
        {Object.entries(filters).map(([key, value]) => {
          if (key === 'entityId') return null;
          return (
            value && (
              <FilterTag
                filter={`${TAGS_NAMES[key]}: ${value}`}
                onClose={() => onRemoveTag(key as keyof UserCredentialsFilters)}
              />
            )
          );
        })}
      </div>
      <UserCredentialsTable />
    </div>
  );
}
