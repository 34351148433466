import './LanguageSelector.css';
import React, { useState } from 'react';
import { supportedLanguages } from '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import ArrowIcon from '../../assets/icons/navigation-icons/arrow.svg';

function LanguageSelector() {
  const { i18n } = useTranslation();
  const [isDropped, setIsDropped] = useState(false);

  return (
    <div>
      <button
        className="language-selector"
        onClick={() => setIsDropped(!isDropped)}
      >
        <input
          type="button"
          value={supportedLanguages[i18n.language]}
          onChange={(e) => i18n.changeLanguage(e.target.value)}
        />
        <img
          src={ArrowIcon}
          className="language-selector_arrow"
          style={{ transform: `rotate(${isDropped ? 270 : 180}deg)` }}
          alt="Language selector arrow"
        />
      </button>
      {isDropped && (
        <div className="options-container">
          {Object.entries(supportedLanguages).map(([langKey, nativeName]) => {
            if (i18n.language === langKey) return null;
            return (
              <button
                key={langKey}
                value={langKey}
                onClick={async (e) => {
                  await i18n.changeLanguage(e.currentTarget.value);
                  setIsDropped(false);
                }}
              >
                {nativeName}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default LanguageSelector;
