import '../SharedEntitiesManagementStyles.css';
import React, { useEffect, useMemo, useState } from 'react';
import BackButton from '../../../components/Buttons/BackButton';
import { FRONTEND_PATHS } from '../../../config';
import {
  Location,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useEntitiesContext } from '../../../contexts/EntitiesContext';
import { Entity } from '../../../domain/entitiesManagementDtos';
import List from '../../../components/List/List';
import { useTranslation } from 'react-i18next';
import ThemedButton from '../../../components/Buttons/ThemedButton';
import { useGenerateModal } from '../../../components/Modal/Modal';
import useEditForm from './useEditForm';
import Banner from '../../../components/Banner/Banner';
import CreateWalletIcon from '../../../assets/icons/create-credential.svg';
import useEntityDetailsColumns from './useEntityDetailsColumns';
import UrlPathHandler from '../../../domain/urlPathHandler';

const TRANSLATION_PREFIX = 'entitiesManagementPage.entityDetails.';

function EntityDetails() {
  const { wallets, setWallets } = useEntitiesContext();
  const [isListLoading, setIsListLoading] = useState<boolean>(true);
  const [isActionLoading, setIsActionLoading] = useState<boolean>(false);
  const [walletIdSelected, setWalletIdSelected] = useState<string>('');
  const navigate = useNavigate();
  const { walletRepo, selectedEntity, setSelectedEntity, entities } =
    useEntitiesContext();
  const { t } = useTranslation();
  const { entityId } = useParams();
  const { state } = useLocation() as Location & { state: { walletId: string } };
  const entity: Entity = useMemo(
    () =>
      selectedEntity ? selectedEntity : { id: '', name: '', vatNumber: '' },
    [selectedEntity],
  );
  const { setWalletToEdit, editFormModalJSX, setIsEditModalOpened } =
    useEditForm();
  const { setIsDeleteModalOpened, DeleteModal } =
    useGenerateModal<'Delete'>('Delete');
  const entityDetailsColumns = useEntityDetailsColumns({
    setWalletToEdit,
    setWalletIdSelected,
    setIsEditModalOpened,
    setIsDeleteModalOpened,
    entity,
  });

  async function handleClick() {
    setIsActionLoading(true);
    const response = await walletRepo.deleteWallet(
      entityId ?? '',
      walletIdSelected,
    );
    setIsDeleteModalOpened(false);
    if ('error' in response) {
      Banner.show(t('errors.deleteWallet'), true);
      setIsActionLoading(false);
      return;
    }
    const filteredWallets = wallets.filter(
      (wallet) => wallet.walletId !== walletIdSelected,
    );
    setWallets(filteredWallets);
    Banner.show(t('successes.deleteWallet'));
    setIsActionLoading(false);
  }

  useEffect(() => {
    if (!entityId || !UrlPathHandler.isUuid(entityId)) {
      navigate(`${FRONTEND_PATHS.ENTITIES_MANAGEMENT}`);
      return;
    }
    if (!entities.length) {
      return;
    }
    const foundSelectedEntity = entities.find(
      (entity) => entity.id === entityId,
    );
    setSelectedEntity(foundSelectedEntity);
    walletRepo
      .getWallets(entityId)
      .then((response) => {
        if ('error' in response) {
          Banner.show(t('errors.getEntityDetails'), true);
          navigate(`${FRONTEND_PATHS.ENTITIES_MANAGEMENT}`);
          return;
        }
        setWallets(response.data);
      })
      .finally(() => setIsListLoading(false));
  }, [entityId, entities]);

  return (
    <section className="main-container" style={{ paddingTop: '40px' }}>
      {editFormModalJSX}
      <DeleteModal title={t(`${TRANSLATION_PREFIX}deleteWallet.title`)}>
        <p className="main-container_modal__paragraph">
          {t(`${TRANSLATION_PREFIX}deleteWallet.disclaimer`)}
        </p>
        <section className="main-container_modal__buttons-section">
          <ThemedButton
            text={t('actions.cancel')}
            onClick={() => setIsDeleteModalOpened(false)}
            background="gray"
          />
          <ThemedButton
            text={t('actions.delete')}
            onClick={handleClick}
            isLoading={isActionLoading}
          />
        </section>
      </DeleteModal>
      <BackButton destinationRoute={FRONTEND_PATHS.ENTITIES_MANAGEMENT} />
      <section className="main-container_create-button">
        <ThemedButton
          text={t(`${TRANSLATION_PREFIX}buttonTitle`)}
          onClick={() => {
            const dynamicPath = FRONTEND_PATHS.CREATE_WALLET.replace(
              ':entityId',
              entity.id,
            );
            navigate(`${FRONTEND_PATHS.ENTITIES_MANAGEMENT}${dynamicPath}`);
          }}
          icon={CreateWalletIcon}
          size="big"
        />
      </section>
      <List
        title={t(`${TRANSLATION_PREFIX}title`, {
          entityName: entity.name,
        })}
        emptyMessage={t(`${TRANSLATION_PREFIX}emptyListMessage`)}
        columns={entityDetailsColumns}
        rows={wallets}
        isLoading={isListLoading}
        highlightElement={
          state ? (wallet) => wallet.walletId === state.walletId : undefined
        }
      />
    </section>
  );
}

export default EntityDetails;
