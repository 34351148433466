import { io } from 'socket.io-client';
import { BACKEND_URL_BASE } from '../config';
import { useEffect, useState } from 'react';

export const socket = io(BACKEND_URL_BASE, {
  transports: ['websocket'],
  reconnectionDelayMax: 10000,
  autoConnect: false,
});

export default function useSocketConnection() {
  const [socketId, setSocketId] = useState<string | undefined>();

  useEffect(() => {
    socket.connect();

    const onConnect = () => {
      if (socket.id !== socketId) {
        setSocketId(socket.id);
      }
    };

    socket.on('connect', onConnect);

    return () => {
      socket.disconnect();
    };
  }, []);

  return { socket, socketId };
}
