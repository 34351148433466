const MARGIN = 80 + 80;
const CLOSED_MENU_WIDTH = 110;
const OPEN_MENU_WIDTH = 360;

function computeTableWidth(width: number, isMenuOpen: boolean) {
  return isMenuOpen
    ? width - MARGIN - OPEN_MENU_WIDTH
    : width - MARGIN - CLOSED_MENU_WIDTH;
}

export default computeTableWidth;
