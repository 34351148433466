import './Header.css';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../contexts/AuthContext';
import { UserAuth } from '../../domain/userAuth';
import { logout } from '../../services/vidcredentials';
import axiosInstance from '../../services/axiosInstance';
import logoutIcon from '../../assets/icons/header-icons/logout.svg';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import UrlPathHandler from '../../domain/urlPathHandler';
import { useGenerateModal } from '../Modal/Modal';
import ThemedButton from '../Buttons/ThemedButton';
import Avatar from '../Avatar/Avatar';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

function Header() {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { accessToken, setToken } = useContext(AuthContext);
  const [userName, setUserName] = useState<string>('-');
  const [entityName, setEntityName] = useState<string>('-');
  const [role, setRole] = useState<string>('');
  const [isLogoutLoading, setIsLogoutLoading] = useState<boolean>(false);
  const { setIsLogoutModalOpened, LogoutModal } =
    useGenerateModal<'Logout'>('Logout');
  const title = useMemo(
    () =>
      t(`headers.${new UrlPathHandler(pathname).getTitleKey()}`, {
        defaultValue: '',
      }),
    [pathname, t],
  );

  async function doLogout() {
    setIsLogoutLoading(true);
    await logout();
    setToken('');
    axiosInstance.defaults.headers.common['Authorization'] = '';
    setIsLogoutLoading(false);
  }

  useEffect(() => {
    if (!accessToken) {
      return;
    }
    const user = new UserAuth(accessToken);
    setUserName(user.getUserName());
    setEntityName(user.getEntityName());
    const userRole = user.getRole();
    setRole(`${userRole.charAt(0).toUpperCase()}${userRole.slice(1)}`);
  }, [accessToken]);

  return (
    <header className="header">
      <LogoutModal title={t('actions.logout')}>
        <p className="header_modal__paragraph">{t(`disclaimers.logout`)}</p>
        <section className="header_modal__buttons-section">
          <ThemedButton
            text={t('actions.cancel')}
            onClick={() => setIsLogoutModalOpened(false)}
            background="gray"
          />
          <ThemedButton
            text={t('actions.logout')}
            onClick={doLogout}
            isLoading={isLogoutLoading}
          />
        </section>
      </LogoutModal>
      <h1>{title}</h1>
      <Breadcrumbs />
      <section className="header_user-section">
        <LanguageSelector />
        <h2 className="header_user-section__info-header">
          {userName} / {role} / {entityName}
        </h2>
        <button
          className="header_user-section__logout-button"
          onClick={() => setIsLogoutModalOpened(true)}
        >
          <img src={logoutIcon} alt="Logout Icon" />
        </button>
        <Avatar role={role} fullName={userName} />
      </section>
    </header>
  );
}

export default Header;
