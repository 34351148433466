import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import EntityRepository from '../services/entityRepository';
import { useRouteSecurization } from '../hooks/useRouteSecurization';
import WalletRepository from '../services/walletRepository';
import DidRepository from '../services/didRepository';
import { Entity, Wallet } from '../domain/entitiesManagementDtos';
import { useLocation, useNavigate } from 'react-router-dom';
import { FRONTEND_PATHS } from '../config';
import Banner from '../components/Banner/Banner';
import { useTranslation } from 'react-i18next';

export const EntitiesContext = createContext<{
  entityRepo: EntityRepository;
  walletRepo: WalletRepository;
  didRepo: DidRepository;
  entities: Entity[];
  setEntities: React.Dispatch<React.SetStateAction<Entity[]>>;
  wallets: Wallet[];
  setWallets: React.Dispatch<React.SetStateAction<Wallet[]>>;
  selectedEntity?: Entity;
  setSelectedEntity: React.Dispatch<React.SetStateAction<Entity | undefined>>;
  selectedWallet?: Wallet;
  setSelectedWallet: React.Dispatch<React.SetStateAction<Wallet | undefined>>;
  getWallets: (entityId: string, walletId: string) => Promise<void>;
}>({
  entityRepo: new EntityRepository(),
  walletRepo: new WalletRepository(),
  didRepo: new DidRepository(),
  entities: [],
  setEntities: () => null,
  wallets: [],
  setWallets: () => null,
  setSelectedEntity: () => null,
  setSelectedWallet: () => null,
  getWallets: async () => undefined,
});

function EntitiesContextProvider({ children }: PropsWithChildren<any>) {
  useRouteSecurization(['superadmin']);
  const [entities, setEntities] = useState<Entity[]>([]);
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [selectedEntity, setSelectedEntity] = useState<Entity>();
  const [selectedWallet, setSelectedWallet] = useState<Wallet>();
  const { entityRepo, walletRepo, didRepo } = useMemo(
    () => ({
      entityRepo: new EntityRepository(),
      walletRepo: new WalletRepository(),
      didRepo: new DidRepository(),
    }),
    [],
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  async function getWallets(entityId: string, walletId: string) {
    setSelectedEntity(entities.find((entity) => entity.id === entityId));
    const response = await walletRepo.getWallets(entityId);
    if ('error' in response) {
      Banner.show(t('errors.getEntityDetails'), true);
      navigate(`${FRONTEND_PATHS.ENTITIES_MANAGEMENT}`);
      return;
    }
    setWallets(response.data);
    const foundSelectedWallet = response.data.find(
      (wallet) => wallet.walletId === walletId,
    );
    if (!foundSelectedWallet) {
      Banner.show(t('errors.getWalletDetails'), true);
      navigate(
        `${
          FRONTEND_PATHS.ENTITIES_MANAGEMENT
        }${FRONTEND_PATHS.ENTITY_DETAILS.replace(':entityId', entityId)}`,
      );
      return;
    }
    setSelectedWallet(foundSelectedWallet);
  }

  useEffect(() => {
    `${pathname}/` !== FRONTEND_PATHS.ENTITIES_MANAGEMENT &&
      entityRepo
        .getEntities()
        .then((response) => 'data' in response && setEntities(response.data));
  }, []);

  return (
    <EntitiesContext.Provider
      value={{
        entityRepo,
        walletRepo,
        didRepo,
        entities,
        setEntities,
        wallets,
        setWallets,
        selectedEntity,
        setSelectedEntity,
        selectedWallet,
        setSelectedWallet,
        getWallets,
      }}
    >
      {children}
    </EntitiesContext.Provider>
  );
}

function useEntitiesContext() {
  return useContext(EntitiesContext);
}

export { EntitiesContextProvider, useEntitiesContext };
