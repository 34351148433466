import axiosInstance from './axiosInstance';
import { BACKEND_URL, PATHS } from '../config';
import { CreateDidRequestBody } from '../domain/entitiesManagementDtos';

class DidRepository {
  async createDid(
    walletId: string,
    createDidRequestBody: CreateDidRequestBody,
  ): Promise<{ status: string } | { error: string }> {
    const dynamicPath = PATHS.CREATE_DID.replace(':walletId', walletId);
    const response = await axiosInstance.post(
      `${BACKEND_URL}${dynamicPath}`,
      createDidRequestBody,
    );
    if (response.status >= 400) {
      return { error: response.statusText };
    }
    return { status: 'OK' };
  }

  async editDidAlias(
    walletId: string,
    did: string,
    alias: string,
  ): Promise<{ status: string } | { error: string }> {
    const dynamicPath = PATHS.EDIT_DID.replace(':walletId', walletId).replace(
      ':did',
      did,
    );
    const response = await axiosInstance.patch(`${BACKEND_URL}${dynamicPath}`, {
      alias,
    });
    if (response.status >= 400) {
      return { error: response.statusText };
    }
    return { status: 'OK' };
  }

  async deleteDid(
    walletId: string,
    did: string,
  ): Promise<{ status: string } | { error: string }> {
    const dynamicPath = PATHS.DELETE_DID.replace(':walletId', walletId).replace(
      ':did',
      did,
    );
    const response = await axiosInstance.delete(`${BACKEND_URL}${dynamicPath}`);
    if (response.status >= 400) {
      return { error: response.statusText };
    }
    return { status: 'OK' };
  }

  async getDidDocument(
    did: string,
  ): Promise<{ data: Record<string, any> } | { error: string }> {
    const dynamicPath = PATHS.GENERATE_DID_DOCUMENT.replace(':did', did);
    const response = await axiosInstance.get(`${BACKEND_URL}${dynamicPath}`);
    if (response.status >= 400) {
      return { error: response.statusText };
    }
    return { data: response.data };
  }

  async activateDid(
    walletId: string,
    did: string,
  ): Promise<{ status: string } | { error: string }> {
    const dynamicPath = PATHS.ACTIVATE_DID.replace(
      ':walletId',
      walletId,
    ).replace(':did', did);
    const response = await axiosInstance.put(`${BACKEND_URL}${dynamicPath}`);
    if (response.status >= 400) {
      return { error: response.statusText };
    }
    return { status: 'OK' };
  }
}

export default DidRepository;
