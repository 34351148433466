import React from 'react';
import { useMemo, useState } from 'react';
import { FormBuilder } from '../../../domain/FormBuilder/FormBuilder';
import { Entity } from '../../../domain/entitiesManagementDtos';
import { useTranslation } from 'react-i18next';
import Banner from '../../../components/Banner/Banner';
import { useGenerateModal } from '../../../components/Modal/Modal';
import { useEntitiesContext } from '../../../contexts/EntitiesContext';

interface UseEditFormReturn {
  editFormModalJSX: JSX.Element;
  setEntityToEdit: React.Dispatch<React.SetStateAction<Entity>>;
  setIsEditModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

function useEditForm(): UseEditFormReturn {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [entityToEdit, setEntityToEdit] = useState<Entity>({
    name: '',
    vatNumber: '',
    id: '',
  });
  const { setIsEditModalOpened, isEditModalOpened, EditModal } =
    useGenerateModal<'Edit'>('Edit');
  const { t } = useTranslation();
  const { entityRepo, setEntities } = useEntitiesContext();
  const initialEntityValues = useMemo(() => entityToEdit, [isEditModalOpened]);

  function handleChange(event: { target: HTMLInputElement }) {
    const { name, value } = event.target;
    setEntityToEdit({
      ...entityToEdit,
      [name]: value.trimStart(),
    });
  }

  async function handleClick() {
    setIsLoading(true);
    const response = await entityRepo.editEntity(entityToEdit);
    setIsLoading(false);
    setIsEditModalOpened(false);
    if ('error' in response) {
      Banner.show(t('errors.editEntity'), true);
      return;
    }
    setEntities((prevState) => {
      const entitiesCopy = [...prevState];
      const editedEntityIndex = prevState.findIndex(
        (entity) => entity.id === entityToEdit.id,
      );
      entitiesCopy.splice(editedEntityIndex, 1, entityToEdit);
      return entitiesCopy;
    });
    Banner.show(t('successes.editEntity'));
  }

  const editFormJSX = useMemo(() => {
    const formBuilder = new FormBuilder();
    formBuilder.addInput('text', entityToEdit.name, 'name', handleChange, {
      label: t('entitiesManagementPage.createEntity.name'),
    });
    formBuilder.addInput(
      'text',
      entityToEdit.vatNumber,
      'vatNumber',
      handleChange,
      { label: t('entitiesManagementPage.createEntity.cif') },
    );
    formBuilder.addButton(t('actions.save'), handleClick, {
      isLoading,
      isDisabled:
        JSON.stringify(entityToEdit) === JSON.stringify(initialEntityValues) ||
        !entityToEdit.vatNumber ||
        !entityToEdit.name,
    });
    return formBuilder.getJSX();
  }, [entityToEdit, t, isLoading]);

  return {
    editFormModalJSX: (
      <EditModal
        title={t('entitiesManagementPage.entitiesManagementSection.editEntity')}
      >
        {editFormJSX}
      </EditModal>
    ),
    setEntityToEdit,
    setIsEditModalOpened,
  };
}

export default useEditForm;
