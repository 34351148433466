import React, { useContext, useState } from 'react';
import './CredentialsTable.css';
import {
  getCredentialView,
  ParsedBackendError,
  resendEmailCredentialOrder,
  revokeVerifiableCredential,
} from '../../../services/vidcredentials';
import { CredentialDetailsModal } from '../CredentialDetailsModal/CredentialDetailsModal';
import {
  CredentialListView,
  CredentialOrderDetailsView,
} from '../../../domain/credentialView';
import EyeIcon from '../../../assets/icons/table-icons/eye.svg';
import RevokeIcon from '../../../assets/icons/table-icons/revoke.svg';
import ResendIcon from '../../../assets/icons/table-icons/resend.svg';
import TooltipItem from '../../../components/ToolTipItem/ToolTipItem';
import { Popup } from '../../../components/Popup/Popup';
import { AllCredentialsContext } from '../../../contexts/CredentialsProviders/Providers/AllCredentialsContext';
import { formatDate } from '../CredentialDetailsModal/helpers/StringHelpers';
import { useTranslation } from 'react-i18next';
import PaginatedTable from '../../../components/TableComponents/PaginatedTable';
import ActionsCell, {
  Action,
} from '../../../components/TableComponents/ActionsCell';

export function CredentialsTable() {
  const { t } = useTranslation();
  const { credentials, isLoading, tableSpecs, loadCredentials, filters } =
    useContext(AllCredentialsContext);
  const [isCredentialDetailsModalOpen, setCredentialDetailsModalOpen] =
    useState(false);

  const [credential, setCredential] = useState(
    {} as CredentialOrderDetailsView,
  );
  const [projection, setProjection] = useState({} as CredentialListView);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [credentialToRevoke, setCredentialToRevoke] = useState<string | null>(
    null,
  );

  const openCredential = async (data: CredentialListView, e: any) => {
    e.preventDefault();
    try {
      const credential = await getCredentialView(data.detailsURL);
      setCredential(credential as CredentialOrderDetailsView);
      setProjection(data);
      setCredentialDetailsModalOpen(true);
    } catch (error) {
      console.log('Error unable to get the credentialDetails');
    }
  };
  const revokeCredential = async (credentialId: string) => {
    try {
      await revokeVerifiableCredential(credentialId);
      setTimeout(() => loadCredentials(10, 1), 5000);
      setSuccessMessage(t('details.revokeSuccess'));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage((error as ParsedBackendError).title);
      setSuccessMessage('');
    }

    setCredentialToRevoke(null);
  };
  const resendEmail = async (resendEmailPath: string, e: any) => {
    try {
      e.preventDefault();
      await resendEmailCredentialOrder(resendEmailPath);
      setSuccessMessage(t('details.emailResentSuccess'));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage((error as ParsedBackendError).title);
      setSuccessMessage('');
    }
  };

  const COLUMNS = [
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'DID',
      field: 'did',
      render: (data: CredentialListView) =>
        !['CREATED', 'VERIFIED', 'UNVERIFIED'].includes(data.status) ? (
          <TooltipItem id={`did-${data.issuanceId}`} title={data.did ?? ''}>
            <h3 id={'did' + data.issuanceId}>{data.did}</h3>
          </TooltipItem>
        ) : (
          t('table.notAvailable')
        ),
    },
    {
      title: t('filters.credentialType'),
      field: 'credentialType',
    },
    {
      title: t('table.creationDate'),
      field: 'creationDate',
      render: (data: CredentialListView) => formatDate(data.creationDate),
    },
    {
      title: t('table.status'),
      field: 'status',
      render: (data: CredentialListView) => t(`status.${data.status}`),
    },
    {
      title: '',
      field: '',
      render: (data: CredentialListView) => {
        const actions: Action[] = [
          {
            onClick: () =>
              setCredentialToRevoke(
                data.revokeURL
                  ? data.revokeURL.split('/').slice(-1).join()
                  : null,
              ),
            labelId: 'revoke' + data.issuanceId,
            labelTitle: t('table.revokeCredential'),
            icon: RevokeIcon,
            isDisabled: !data.revokeURL,
          },
          {
            onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
              resendEmail(data.resendURL as string, e),
            labelId: 'resend' + data.issuanceId,
            labelTitle: t('table.resendEmail'),
            icon: ResendIcon,
            isDisabled: data.status !== 'VERIFIED',
          },
          {
            onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
              openCredential(data, e),
            labelId: 'detail' + data.issuanceId,
            labelTitle: t('table.viewDetails'),
            icon: EyeIcon,
          },
        ];
        return (
          <ActionsCell
            key={`credential-actions-${data.did}`}
            actions={actions}
          />
        );
      },
    },
  ];

  return (
    <>
      <CredentialDetailsModal
        isOpen={isCredentialDetailsModalOpen}
        setIsOpen={setCredentialDetailsModalOpen}
        credential={credential}
        projection={projection}
      />
      <PaginatedTable
        tableSpecs={tableSpecs}
        columns={COLUMNS}
        data={credentials}
        isLoading={isLoading}
        filters={filters}
        paginationLoader={loadCredentials}
      />
      {errorMessage && (
        <Popup
          error
          message={errorMessage}
          onClose={() => setErrorMessage('')}
        />
      )}
      {successMessage && (
        <Popup
          success
          message={successMessage}
          onClose={() => setSuccessMessage('')}
        />
      )}
      {credentialToRevoke && (
        <Popup
          confirmation
          message={t('table.sureRevoke')}
          onConfirm={() => revokeCredential(credentialToRevoke)}
          onReject={() => setCredentialToRevoke(null)}
          onClose={() => setCredentialToRevoke(null)}
        />
      )}
    </>
  );
}
