import './TableComponents.css';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Column } from 'material-table';
import useScreenDimensions from '../../services/useScreenDimensions';
import { MenuContext } from '../../contexts/MenuContext';
import computeTableWidth from './computeTableWidth';

interface RowProps {
  columns: Partial<Column<any>>[];
  data: Record<string, any>;
  isHighlighted?: boolean;
}

function Row({ columns, data, isHighlighted }: RowProps) {
  const { width } = useScreenDimensions();
  const { isMenuOpen } = useContext(MenuContext);
  const rowWidth = useMemo(
    () => computeTableWidth(width, isMenuOpen),
    [width, isMenuOpen],
  );
  const highlightedRowRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    highlightedRowRef.current &&
      highlightedRowRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <li
      ref={isHighlighted ? highlightedRowRef : null}
      className="table-row"
      style={{
        width: rowWidth,
        gridTemplateColumns: `repeat(${columns.length},  calc(${rowWidth}px / ${columns.length}) )`,
        borderColor: `var(--${isHighlighted ? 'primary' : 'light-gray'})`,
      }}
    >
      {columns.map(({ render, field }, index) => {
        if (render) {
          const renderResult = render(data, 'row');
          return typeof renderResult === 'string' ||
            typeof renderResult === 'number' ? (
            <h3 key={`row-custom-render-header-${index}`}>{renderResult}</h3>
          ) : (
            renderResult
          );
        }
        const fieldName = typeof field === 'string' ? field : '';
        return <h3 key={`row-header-${index}`}>{data[fieldName]}</h3>;
      })}
    </li>
  );
}

export default Row;
