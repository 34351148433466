import { useContext, useMemo, useState } from 'react';
import { convertToQueryString } from '../../../pages/Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { FormBuilder, SelectInputOption } from '../FormBuilder';
import {
  CredentialsContextType,
  CredentialsFilters,
} from '../../../contexts/CredentialsProviders/Providers/AllCredentialsContext';
import { placeholders, statusOptions } from './buildsConstants';
import { useTranslation } from 'react-i18next';

interface CredentialsFilterFormProps {
  context: CredentialsContextType;
  closeModal: () => void;
}

function CredentialsFilterForm({
  context,
  closeModal,
}: CredentialsFilterFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { filters, setFilters, loadCredentials, credentialTypes, tableSpecs } =
    useContext(context);
  const cachedFilterState = useMemo(() => filters, []);
  const { t } = useTranslation();
  const credentialTypesOptions: SelectInputOption[] = [
    { name: t('filters.credentialType'), value: '' },
    ...credentialTypes.map(({ name }) => ({ value: name, name })),
  ];

  const handleClose = () => {
    setFilters(cachedFilterState);
    closeModal();
  };

  const applyFilter = async () => {
    setIsLoading(true);
    const queryParams = convertToQueryString(filters);
    await loadCredentials(tableSpecs.tableSize, 1, queryParams, true);
    setIsLoading(false);
    closeModal();
  };

  const handleChange = (event: { target: HTMLInputElement }) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  return useMemo(() => {
    const formBuilder = new FormBuilder();
    const { status, types, ...textInputFilters } = filters;
    formBuilder.addInput('select', filters.status, 'status', () => null, {
      selectInputOptions: statusOptions,
      placeholder: statusOptions[0].name,
      initialSelectedOption: statusOptions.find(
        (statusOption) => statusOption.value === cachedFilterState.status,
      ),
      onSelectOption: ({ value }) => setFilters({ ...filters, status: value }),
    });
    formBuilder.addInput('select', filters.types, 'types', () => null, {
      selectInputOptions: credentialTypesOptions,
      placeholder: credentialTypesOptions[0].name,
      initialSelectedOption: credentialTypesOptions.find(
        (credentialTypeOption) =>
          credentialTypeOption.value === cachedFilterState.types,
      ),
      onSelectOption: ({ value }) => setFilters({ ...filters, types: value }),
    });
    Object.keys(textInputFilters).map((key, _) => {
      formBuilder.addInput(
        'text',
        filters[key as keyof CredentialsFilters],
        key,
        handleChange,
        {
          placeholder: placeholders[key],
        },
      );
    });
    formBuilder.addButton(t('actions.cancel'), handleClose, {
      background: 'gray',
    });
    formBuilder.addButton(t('actions.filter'), applyFilter, { isLoading });
    return formBuilder.getJSX();
  }, [filters, isLoading]);
}

export default CredentialsFilterForm;
