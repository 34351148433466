import { useState } from 'react';
import { Input, InputGroup, Pagination, PaginationItem } from 'reactstrap';
import { Device } from '../../../domain/devices';
import deviceIcon from '../../../assets/icons/device-icon.png';
import searchIcon from '../../../assets/icons/table-icons/search.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  devicesList: Device[];
  selectedDevice: string;
  selectDevice: (deviceId: string) => void;
}

const chunk = (inputArray: any[], chunkSize: number) => {
  if (chunkSize <= 0) throw 'Invalid chunk size';
  const chunkArray = [];
  for (let i = 0, len = inputArray.length; i < len; i += chunkSize)
    chunkArray.push(inputArray.slice(i, i + chunkSize));
  return chunkArray;
};

export const DevicesList = ({
  devicesList,
  selectedDevice,
  selectDevice,
}: Props) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');

  const filteredDevices = devicesList.filter((device: Device) => {
    const { deviceId, deviceName } = device;
    return (
      deviceId.toLowerCase().includes(searchText.toLowerCase()) ||
      deviceName.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const PAGE_SIZE = 10;
  const chunkedArray = chunk(filteredDevices, PAGE_SIZE);
  const totalPages = chunkedArray.length;

  const [currentPage, setCurrentPage] = useState(1);

  const paginatedResults = chunkedArray[currentPage - 1];

  const showPrevious = currentPage !== 1;
  const showNext = currentPage !== totalPages;

  return (
    <>
      <p>{t('details.selectDevice')}</p>
      <InputGroup className="input-finder-search-bar">
        <img className="search-icon" src={searchIcon} width={26} height={26} />
        <Input
          className="custom-searchbar-input"
          placeholder={t('actions.search')}
          value={searchText}
          onChange={(event) => {
            setCurrentPage(1);
            setSearchText(event.target.value);
          }}
        />{' '}
      </InputGroup>
      <div className="devices-container">
        {paginatedResults?.map((device: Device, i: number) => {
          const { deviceName, deviceId } = device;
          return (
            <div
              className={`device-container ${
                selectedDevice === deviceId ? 'selected-device' : ''
              }`}
              key={i}
              onClick={() => selectDevice(deviceId)}
            >
              <img src={deviceIcon} alt="Device" />
              <div className="device-info-container">
                <p className="device-name">{deviceId}</p>
                <p className="device-description">{deviceName}</p>
              </div>
            </div>
          );
        })}
      </div>
      <Pagination className="pagination-container">
        <PaginationItem
          className={!showPrevious ? 'hidden' : ''}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          {t('details.previous')}
        </PaginationItem>
        {showNext && (
          <PaginationItem onClick={() => setCurrentPage(currentPage + 1)}>
            {t('details.next')}
          </PaginationItem>
        )}
      </Pagination>
    </>
  );
};
