import './Login.css';
import React, { useEffect, useState, useRef } from 'react';
import VIDidentityLogo from '../../assets/vidcredentials-logo.svg';
import google from './../../assets/google.svg';
import apple from './../../assets/apple.svg';
import hero from './../../assets/login-background.svg';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import { useTranslation } from 'react-i18next';
import { DEMO_URL, FRONTEND_PATHS } from '../../config';
import { useNavigate } from 'react-router-dom';
import {
  generateCodeChallenge,
  getLoginSessionStatus,
  LoginSessionStatus,
  obtainQrCode,
  PollingResponse,
} from '../../services/vidcredentials';
import { v4 as uuidv4 } from 'uuid';
import Banner from '../../components/Banner/Banner';

export function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [qrCodeUrl, setQrCodeUrl] = useState<string>('');

  const [isPollingEnabled, setPollingEnabled] = useState(false);
  const [isPollingSuccessful, setIsPollingSuccessful] = useState(false);
  const [pollingDataResponse, setPollingDataResponse] = useState({});
  const intervalIdRef = useRef<NodeJS.Timer | null>(null);

  const fetchData = async (verificationRequestId: string) => {
    const response = (await getLoginSessionStatus(
      verificationRequestId,
    )) as PollingResponse;
    if (
      'error' in response ||
      (response.status && response.status === LoginSessionStatus.FAILED)
    ) {
      setPollingEnabled(false);
      intervalIdRef.current && clearInterval(intervalIdRef.current);
      Banner.show(t('login.try_again'), true);
      setQrCodeUrl('');
      navigate(FRONTEND_PATHS.LOGIN);
      return;
    }
    if (response.status === LoginSessionStatus.SUCCEEDED) {
      setPollingEnabled(false);
      setPollingDataResponse(response);
      intervalIdRef.current && clearInterval(intervalIdRef.current);
      setIsPollingSuccessful(true);
    }
  };

  useEffect(() => {
    if (!isPollingEnabled && intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
    if (isPollingSuccessful) {
      const { code } = pollingDataResponse as PollingResponse;
      navigate(`${FRONTEND_PATHS.LOGIN_CALLBACK}?code=${code}`);
    }
    return;
  }, [isPollingEnabled, isPollingSuccessful, navigate]);

  const signIn = async () => {
    try {
      const { code_verifier, code_challenge } = await generateCodeChallenge();
      const verificationRequestId = uuidv4();
      localStorage.setItem('codeVerifier', code_verifier);
      localStorage.setItem('sessionId', verificationRequestId);
      const response = (await obtainQrCode({
        verificationRequestId: verificationRequestId,
        codeChallenge: code_challenge,
      })) as {
        qrBase64: string;
        rawOpenid4vp: string;
      };
      if ('error' in response) {
        Banner.show(t('login.try_again'), true);
        setQrCodeUrl('');
        navigate(FRONTEND_PATHS.LOGIN);
        return;
      }
      setQrCodeUrl(response.qrBase64);
      setPollingEnabled(true);
      intervalIdRef.current = setInterval(
        () => fetchData(verificationRequestId),
        2000,
      );
    } catch (error) {
      console.error('Error fetching QR code');
    }
  };

  return (
    <div className="blue-login">
      <header className="login-header">
        <img src={VIDidentityLogo} alt="VIDidentity logo" />
      </header>
      <section className="language-selector-wrapper">
        <LanguageSelector />
      </section>
      <section className="home-container">
        <div className="info-column">
          <h1>{t('login.control')}</h1>
          <p>{t('login.with_vidchain')}</p>
          <div className="vid-chain-buttons-container">
            <button className="new-sign-in-vid-chain" onClick={() => signIn()}>
              <span>{t('login.login_with')}</span>
              <span>&rarr;</span>
            </button>
            <a
              className="new-sign-in-vid-chain"
              href={DEMO_URL}
              target="_blank"
            >
              <span>{t('login.more_info')}</span>
              <span>&rarr;</span>
            </a>
          </div>
        </div>
        <div className="background-column">
          {qrCodeUrl ? (
            <img src={qrCodeUrl} alt="QR Code" className="img-qr" />
          ) : (
            <img src={hero} alt="Background" />
          )}
        </div>
      </section>
      <div className="download-container">
        <span>{t('login.get_it')}:</span>
        <div className="apps-container">
          <a
            href="https://play.google.com/store/apps/details?id=com.validatedid.wallet&hl=es_VE&gl=US"
            target="_blank"
          >
            <img src={google} alt="App" />
          </a>
          <a
            href="https://apps.apple.com/es/app/vidwallet/id1554340592"
            target="_blank"
          >
            <img src={apple} alt="App" />
          </a>
        </div>
      </div>
      <Banner />
    </div>
  );
}
