import './ThemedInput.css';
import React, { HTMLInputTypeAttribute, useEffect, useState } from 'react';
import { SelectInputOption } from '../../domain/FormBuilder/FormBuilder';
import { InputProps } from 'reactstrap/types/lib/Input';
import ArrowIcon from '../../assets/icons/navigation-icons/arrow.svg';
import InvalidIcon from '../../assets/icons/invalid.svg';

export type InputOptions = Omit<InputProps, 'value' | 'type' | 'onChange'> & {
  selectInputOptions?: SelectInputOption[];
  onSelectOption?: (option: SelectInputOption) => void;
  initialSelectedOption?: SelectInputOption;
  placeholder?: string;
  description?: string;
};

interface ThemedInputProps {
  type: HTMLInputTypeAttribute;
  value: string;
  name: string;
  errorMessage?: string;
  onChange?: React.ChangeEventHandler<any>;
  index?: number;
  inputOptions?: InputOptions;
}

interface ThemedSelectInputProps {
  options: SelectInputOption[];
  onSelectOption: (option: SelectInputOption) => void;
  initialSelectedOption?: SelectInputOption;
  placeholder?: string;
}

function ThemedSelectInput({
  options,
  onSelectOption,
  initialSelectedOption,
  placeholder,
}: ThemedSelectInputProps) {
  const [isDropped, setIsDropped] = useState(false);
  const [selectedOption, setSelectedOption] = useState<SelectInputOption>(
    initialSelectedOption ?? options[0] ?? { name: '', value: '' },
  );

  useEffect(() => {
    onSelectOption(selectedOption);
  }, [selectedOption.value]);

  return (
    <section className="select-container">
      <button
        data-testid="select-input"
        className="select-container_dropdown-button"
        type="button"
        value={selectedOption.value}
        onClick={() => setIsDropped(!isDropped)}
      >
        <h3 style={{ opacity: selectedOption.value ? 1 : 0.3 }}>
          {selectedOption.value ? selectedOption.name : placeholder}
        </h3>
        <img
          src={ArrowIcon}
          style={{ transform: `rotate(${isDropped ? 270 : 180}deg)` }}
          alt="Input select arrow"
        />
      </button>
      {isDropped && (
        <section className="select-container_options-container">
          {options.map(({ name, value }) => {
            return (
              <button
                data-testid={`select-input-option-${name}`}
                key={`option-${name}`}
                type="button"
                className={`select-container_options-container_option ${
                  selectedOption.value === value ? 'selected-option' : ''
                }`}
                onClick={() => {
                  setSelectedOption({ name, value });
                  setIsDropped(!isDropped);
                }}
                value={value}
              >
                <h3 style={{ opacity: value ? 1 : 0.3 }}>
                  {value ? name : placeholder}
                </h3>
              </button>
            );
          })}
        </section>
      )}
    </section>
  );
}

function ThemedInput({
  type,
  value,
  name,
  errorMessage,
  onChange,
  index,
  inputOptions = { selectInputOptions: [] },
}: ThemedInputProps) {
  const {
    selectInputOptions,
    onSelectOption,
    placeholder,
    label,
    initialSelectedOption,
    description,
    ...inputProps
  } = inputOptions;

  return (
    <section key={`form-entry-${index ?? 0}`} className="input-wrapper">
      <label>{label}</label>
      {description && <p>{description}</p>}
      {type === 'select' ? (
        <ThemedSelectInput
          options={selectInputOptions ?? []}
          onSelectOption={onSelectOption ? onSelectOption : () => null}
          initialSelectedOption={initialSelectedOption}
          placeholder={placeholder}
        />
      ) : (
        <section className="input-wrapper_input-section">
          {!!errorMessage && (
            <section className="input-wrapper_input-section__invalid-section">
              <img src={InvalidIcon} alt="Invalid input icon" />
              <h4>{errorMessage}</h4>
            </section>
          )}
          <input
            data-testid="text-input"
            className="input-wrapper_input-section__input"
            value={value}
            type={type}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            style={{
              borderColor: `var(--${
                errorMessage ? 'error-font-color' : 'light-gray'
              })`,
              color: errorMessage ? 'var(--error-font-color)' : '',
            }}
            {...inputProps}
          />
        </section>
      )}
    </section>
  );
}

export default ThemedInput;
