import { useEffect, useState } from 'react';

function useScreenDimensions() {
  const [screenDimensions, setScreenDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  function handleResize(event: UIEvent) {
    const window = event.target as Window;
    const { innerHeight, innerWidth } = window;
    setScreenDimensions({
      height: innerHeight,
      width: innerWidth,
    });
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenDimensions;
}

export default useScreenDimensions;
