import React from 'react';
import { Entity, Wallet } from '../../../domain/entitiesManagementDtos';
import ActionsCell, {
  Action,
} from '../../../components/TableComponents/ActionsCell';
import EyeIcon from '../../../assets/icons/table-icons/eye.svg';
import PencilIcon from '../../../assets/icons/table-icons/pencil.svg';
import DeleteIcon from '../../../assets/icons/table-icons/delete.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FRONTEND_PATHS } from '../../../config';
import { useEntitiesContext } from '../../../contexts/EntitiesContext';

interface UseEntityDetailsColumnsProps {
  setWalletToEdit: React.Dispatch<
    React.SetStateAction<Omit<Wallet, 'keyManagementServiceId' | 'dids'>>
  >;
  setWalletIdSelected: React.Dispatch<React.SetStateAction<string>>;
  setIsEditModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeleteModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  entity: Entity;
}
const TRANSLATION_PREFIX = 'entitiesManagementPage.entityDetails.';

function useEntityDetailsColumns({
  setWalletToEdit,
  setWalletIdSelected,
  setIsEditModalOpened,
  setIsDeleteModalOpened,
  entity,
}: UseEntityDetailsColumnsProps) {
  const navigate = useNavigate();
  const { setSelectedWallet } = useEntitiesContext();
  const { t } = useTranslation();

  return [
    {
      field: 'alias',
      title: t(`${TRANSLATION_PREFIX}columns.alias`),
    },
    {
      field: 'dids',
      title: t(`${TRANSLATION_PREFIX}columns.dids`),
      render: (wallet: Wallet) => wallet.dids.length,
    },
    {
      field: '',
      title: '',
      render: (wallet: Wallet) => {
        const actions: Action[] = [
          {
            onClick: () => {
              const dynamicPath = FRONTEND_PATHS.WALLET_DETAILS.replace(
                ':entityId',
                entity.id,
              ).replace(':walletId', wallet.walletId);
              setSelectedWallet(wallet);
              navigate(`${FRONTEND_PATHS.ENTITIES_MANAGEMENT}${dynamicPath}`);
            },
            labelId: `view-${wallet.walletId}`,
            labelTitle: t('table.viewDetails'),
            icon: EyeIcon,
          },
          {
            onClick: () => {
              setWalletToEdit({
                alias: wallet.alias,
                walletId: wallet.walletId,
              });
              setIsEditModalOpened(true);
            },
            labelId: `edit-${wallet.walletId}`,
            labelTitle: t('actions.edit'),
            icon: PencilIcon,
          },
          {
            onClick: () => {
              setIsDeleteModalOpened(true);
              setWalletIdSelected(wallet.walletId);
            },
            labelId: `delete-${wallet.walletId}`,
            labelTitle: t('actions.delete'),
            icon: DeleteIcon,
          },
        ];
        return (
          <ActionsCell
            key={`wallet-actions-${wallet.walletId}`}
            actions={actions}
          />
        );
      },
    },
  ];
}

export default useEntityDetailsColumns;
