import Row from './Row';
import Header from './TableHeader';
import { Spinner } from '../Spinners/CircularSpinner';
import CustomPagination from '../TablePagination/CustomPagination';
import MaterialTable, { Column } from 'material-table';
import React, { useContext } from 'react';
import { TableSpecs } from '../../pages/Credentials/Credentials';
import { CredentialsFilters } from '../../contexts/CredentialsProviders/Providers/AllCredentialsContext';
import { useTranslation } from 'react-i18next';
import { UserCredentialsFilters } from '../../contexts/CredentialsProviders/Providers/UserCredentialsContext';
import useScreenDimensions from '../../services/useScreenDimensions';
import computeTableWidth from './computeTableWidth';
import { MenuContext } from '../../contexts/MenuContext';

interface PaginatedTableProps {
  tableSpecs: TableSpecs;
  columns: Partial<Column<any>>[];
  data: any[];
  isLoading: boolean;
  filters?: CredentialsFilters | Partial<UserCredentialsFilters>;
  paginationLoader: (
    tableSize: number,
    page: number,
    filterQuery?: string,
  ) => Promise<void>;
}

function PaginatedTable({
  tableSpecs,
  columns,
  data,
  isLoading,
  filters,
  paginationLoader,
}: PaginatedTableProps) {
  const { t } = useTranslation();
  const { height, width } = useScreenDimensions();
  const { isMenuOpen } = useContext(MenuContext);

  return (
    <MaterialTable
      key={tableSpecs.tableSize}
      columns={columns}
      isLoading={isLoading}
      data={data}
      localization={{
        body: {
          emptyDataSourceMessage: t('table.empty'),
        },
      }}
      title={''}
      style={{
        border: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        width: computeTableWidth(width, isMenuOpen),
        transition: 'var(--default-transition)',
      }}
      options={{
        selection: false,
        search: false,
        pageSize: tableSpecs.tableSize,
        maxBodyHeight: height * (height > 1100 ? 0.8 : 0.5),
        emptyRowsWhenPaging: false,
        overflowY: undefined,
      }}
      components={{
        Header,
        Row,
        OverlayLoading: () => (
          <div
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spinner />
          </div>
        ),
        Pagination: () => (
          <CustomPagination
            tableSpecs={tableSpecs}
            loadCredentials={paginationLoader}
            filters={filters}
          />
        ),
      }}
    />
  );
}

export default PaginatedTable;
