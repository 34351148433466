export const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
export interface Entity {
  id: string;
  name: string;
  vatNumber: string;
}
export interface Did {
  alias: string;
  did: string;
  didMethod: string;
  isActive: boolean;
  url: string;
}
export interface Wallet {
  walletId: string;
  dids: Did[];
  alias: string;
  keyManagementServiceId: string;
}
export interface KeyStorageInfo {
  keyStorageId: string;
  type: string;
}
export interface CreateWalletRequestBody {
  alias: string;
  entityId: string;
  keyStorageId: string;
  keyStorageDescription: string;
  keyManagementServiceId?: string;
}
export enum SupportedAlgorithms {
  ES256 = 'ES256',
  ES256K = 'ES256K',
}
export enum DidMethods {
  key = 'did:key',
  web = 'did:web',
  ebsi = 'did:ebsi',
}
export interface CreateDidRequestBody {
  method: DidMethods;
  alias: string;
  opts?: { url?: string; alg?: SupportedAlgorithms };
}
