import React from 'react';
import { Outlet } from 'react-router-dom';
import { LateralMenu } from '../components/LateralMenu/LateralMenu';
import Header from '../components/Header/Header';
import VIDSpinner from '../components/Spinners/VIDSpinner';
import Banner from '../components/Banner/Banner';

function HeaderWithLateralMenu() {
  return (
    <main
      style={{
        width: '100vw',
        display: 'flex',
      }}
    >
      <VIDSpinner />
      <LateralMenu />
      <div
        style={{
          position: 'relative',
          margin: '50px 80px',
          flex: 1,
        }}
      >
        <Header />
        <Banner />
        <Outlet />
      </div>
    </main>
  );
}

export default HeaderWithLateralMenu;
