import React, { useEffect, useState } from 'react';
import Stepper, { Step } from '../../components/Stepper/Stepper';
import BackButton from '../../components/Buttons/BackButton';
import { FRONTEND_PATHS } from '../../config';
import { CreateWalletRequestBody } from '../../domain/entitiesManagementDtos';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import FirstStep from '../../components/CreateWalletSteps/FirstStep';
import SecondStep from '../../components/CreateWalletSteps/SecondStep';
import Summary from '../../components/Summary/Summary';
import Banner from '../../components/Banner/Banner';
import { useEntitiesContext } from '../../contexts/EntitiesContext';
import UrlPathHandler from '../../domain/urlPathHandler';

function CreateWallet() {
  const [isLastStep, setIsLastStep] = useState<boolean>(false);
  const [keyStorageName, setKeyStorageName] = useState<string>('');
  const [wallet, setWallet] = useState<CreateWalletRequestBody>({
    entityId: '',
    alias: '',
    keyStorageId: '',
    keyStorageDescription: 'Default description',
  });
  const navigate = useNavigate();
  const { entityId } = useParams();
  const { t } = useTranslation();
  const { walletRepo, entities, setSelectedEntity } = useEntitiesContext();

  const STEPS: Step[] = [
    {
      title: t('entitiesManagementPage.createWallet.steps.first'),
      isNextDisabled: !wallet.alias,
      renderStep: () => (
        <FirstStep alias={wallet.alias} setWallet={setWallet} />
      ),
    },
    {
      title: t('entitiesManagementPage.createWallet.steps.second'),
      isNextDisabled: !wallet.keyStorageId,
      renderStep: () => (
        <SecondStep
          keyStorageId={wallet.keyStorageId}
          setWallet={setWallet}
          setKeyStorageName={setKeyStorageName}
        />
      ),
      onNextCLicked: () => {
        setIsLastStep(true);
        return true;
      },
    },
  ];

  async function onCreate() {
    const response = await walletRepo.createWallet(wallet);
    if ('error' in response) {
      Banner.show(t('errors.createWallet'), true);
      return;
    }
    Banner.show(t('successes.createWallet'));
    const dynamicPath = FRONTEND_PATHS.ENTITY_DETAILS.replace(
      ':entityId',
      wallet.entityId,
    );
    navigate(`${FRONTEND_PATHS.ENTITIES_MANAGEMENT}${dynamicPath}`, {
      state: { walletId: response.walletId },
    });
  }

  useEffect(() => {
    if (!entityId || !UrlPathHandler.isUuid(entityId)) {
      navigate(FRONTEND_PATHS.ENTITIES_MANAGEMENT);
      return;
    }
    if (!entities.length) {
      return;
    }
    const foundEntity = entities.find((entity) => entity.id === entityId);
    if (!foundEntity) {
      Banner.show(t('errors.getEntityDetails'), true);
      navigate(FRONTEND_PATHS.ENTITIES_MANAGEMENT);
      return;
    }
    setSelectedEntity(foundEntity);
    setWallet({ ...wallet, entityId });
  }, [entities]);

  return (
    <section
      style={{
        position: 'relative',
        display: 'flex',
        height: '80%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '30px',
      }}
    >
      <BackButton
        destinationRoute={`${
          FRONTEND_PATHS.ENTITIES_MANAGEMENT
        }${FRONTEND_PATHS.ENTITY_DETAILS.replace(':entityId', entityId ?? '')}`}
      />
      <section style={{ display: isLastStep ? '' : 'none', height: '100%' }}>
        <Summary
          summaryTitle={t('entitiesManagementPage.createWallet.summary')}
          onCreate={onCreate}
          onClickBack={() => setIsLastStep(false)}
          entries={[
            {
              value: wallet.alias,
              label: t('entitiesManagementPage.createWallet.alias'),
            },
            { value: keyStorageName, label: 'Key Storage' },
          ]}
        />
      </section>
      <section style={{ display: isLastStep ? 'none' : '', height: '100%' }}>
        <Stepper steps={STEPS} />
      </section>
    </section>
  );
}

export default CreateWallet;
