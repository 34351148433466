import './SharedButtonStyles.css';
import React, { MouseEventHandler } from 'react';

interface ThemedButtonProps {
  text: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  icon?: string;
  size?: 'big' | 'medium' | 'small';
  background?: 'white' | 'green' | 'lightGreen' | 'gray';
  isDisabled?: boolean;
  isLoading?: boolean;
}

const BACKGROUND_STYLES: Record<
  Exclude<ThemedButtonProps['background'], undefined>,
  React.CSSProperties
> = {
  white: { backgroundColor: 'var(--white)', color: 'var(--black)' },
  green: { backgroundColor: 'var(--primary)', color: 'var(--white)' },
  lightGreen: {
    backgroundColor: 'var(--primary-light)',
    color: 'var(--primary-font-color)',
  },
  gray: { backgroundColor: 'var(--button-bg-gray)' },
};
const SIZE_STYLES: Record<
  Exclude<ThemedButtonProps['size'], undefined>,
  React.CSSProperties
> = {
  big: { height: '55px', borderRadius: '45px' },
  medium: { height: '60px', borderRadius: '30px' },
  small: {
    height: '50px',
    borderRadius: '10px',
    width: '180px',
    justifySelf: 'center',
    boxShadow: 'none',
  },
};

function ThemedButton({
  text,
  onClick,
  icon,
  size,
  background,
  isDisabled,
  isLoading,
}: ThemedButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={isDisabled || isLoading}
      className="themed-button"
      style={{
        ...BACKGROUND_STYLES[background ?? 'green'],
        ...SIZE_STYLES[size ?? 'medium'],
      }}
    >
      {isLoading ? (
        <span
          className="themed-button_spinner"
          style={{
            borderColor: BACKGROUND_STYLES[background ?? 'green'].color,
          }}
        />
      ) : (
        <>
          {icon && <img src={icon} alt={`${text} icon`} />}
          <h3
            style={{
              fontSize: `${size === 'small' ? 16 : 18}px`,
              fontWeight: size === 'small' ? 100 : 700,
            }}
          >
            {text}
          </h3>
        </>
      )}
    </button>
  );
}

export default ThemedButton;
