import './TooltipItem.css';
import React, { PropsWithChildren, useState } from 'react';
import { Tooltip } from 'reactstrap';
import { PopperPlacementType } from '@mui/material';
import CheckIcon from '../../assets/icons/check.svg';
import CopyIcon from '../../assets/icons/copy.svg';

interface ToolTipItemProps {
  id: string;
  title: string;
  position?: PopperPlacementType;
  canCopy?: boolean;
}

const TooltipItem = ({
  position = 'bottom',
  id,
  title,
  canCopy,
  children,
}: PropsWithChildren<ToolTipItemProps>) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const parsedId = id.match(/[a-zA-Z0-9]+/g)?.join('');

  return (
    <span>
      <span id={`tooltip-${parsedId}`}>{children}</span>
      <Tooltip
        placement={position}
        isOpen={tooltipOpen}
        target={`tooltip-${parsedId}`}
        autohide={false}
        toggle={() => setTooltipOpen(!tooltipOpen)}
      >
        <section
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {canCopy && (
            <button
              className="copy-tooltip-button"
              style={{ opacity: isHovering ? 1 : 0 }}
              disabled={isClicked}
              onClick={async () => {
                await navigator.clipboard.writeText(title);
                setIsClicked(true);
                setTimeout(() => setIsClicked(false), 3000);
              }}
            >
              <img src={isClicked ? CheckIcon : CopyIcon} alt="Copy icon" />
            </button>
          )}
          <p>{title}</p>
        </section>
      </Tooltip>
    </span>
  );
};

export default TooltipItem;
