import './LateralMenuLink.css';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MenuContext } from '../../../contexts/MenuContext';
import { useTranslation } from 'react-i18next';

interface LateralMenuLinkProps {
  translationKey: string;
  sectionPath: string;
  icon: string;
  isNew?: boolean;
}

function LateralMenuLink({
  translationKey,
  sectionPath,
  icon,
  isNew,
}: LateralMenuLinkProps) {
  const { isMenuOpen } = useContext(MenuContext);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isSelected = pathname.includes(sectionPath);

  return (
    <Link
      to={sectionPath}
      className={`lateral-menu_link ${isSelected ? 'selected' : ''}`}
      style={{
        justifyContent: isMenuOpen ? 'flex-start' : 'center',
        padding: isMenuOpen ? '25px' : '0',
        width: isMenuOpen ? '100%' : '60px',
      }}
    >
      <img
        src={icon}
        style={{ filter: isSelected ? 'brightness(0) invert(1)' : '' }}
        alt={`${t(`menu.${translationKey}`)} icon`}
      />
      {isMenuOpen && <h2>{t(`menu.${translationKey}`)}</h2>}
      {isNew && isMenuOpen && <h3>{t('headers.new')}</h3>}
      {isNew && !isMenuOpen && <div className="lateral-menu_link__circle" />}
    </Link>
  );
}

export default LateralMenuLink;
