import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginCallback.css';
import background from '../../assets/background.svg';
import errorImage from '../../assets/close-browser-error.svg';
import { Container } from 'reactstrap';
import { CallbackHeader } from '../../components/CallbackHeader/CallbackHeader';
import { SuccessMessage } from '../../components/SuccessMessage/SuccessMessage';
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage';
import { authLogin } from '../../services/vidcredentials';
import axiosInstance from '../../services/axiosInstance';
import { AuthContext } from '../../contexts/AuthContext';
import { Spinner } from '../../components/Spinners/CircularSpinner';
import { useTranslation } from 'react-i18next';
import { DEMO_URL } from '../../config';

export function LoginCallback() {
  const { t } = useTranslation();
  const { setToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [hasBeenSuccess, setHasBeenSuccess] = useState(false);
  const [hasBeenError, setHasBeenError] = useState(false);

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get('code');
    if (!code) {
      setHasBeenError(true);
      setLoading(false);
      return;
    }

    const login = async () => {
      try {
        const sessionToken = await authLogin(code);
        if ('error' in sessionToken) {
          setHasBeenError(true);
          setLoading(false);
          return;
        }
        setLoading(false);
        setHasBeenSuccess(true);
        localStorage.removeItem('codeVerifier');
        localStorage.removeItem('sessionId');
        const accessToken = sessionToken.access_token;

        axiosInstance.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${accessToken}`;
        setToken(accessToken);

        navigate('/credentials');
      } catch (error) {
        setLoading(false);
        setHasBeenError(true);
        return;
      }
    };
    login();
  }, []);
  return (
    <>
      <Container>
        <CallbackHeader />
        <section className="home-container">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="info-column">
                <h6>By Validated ID</h6>
                {hasBeenSuccess && (
                  <SuccessMessage
                    messageTitle={t('login.successful')}
                    message={t('login.redirected')}
                  />
                )}
                {hasBeenError && (
                  <ErrorMessage
                    messageTitle={t('login.failed')}
                    message={t('login.try_again')}
                  />
                )}
                <div className="vid-chain-buttons-container">
                  {hasBeenSuccess && (
                    <a
                      className="sign-in-vid-chain"
                      href={DEMO_URL}
                      target="_blank"
                    >
                      <span>{t('login.more_info')}</span>
                      <span>&rarr;</span>
                    </a>
                  )}
                </div>
              </div>
              <div className="background-column">
                <img
                  className={hasBeenSuccess ? 'success-image' : 'error-image'}
                  src={hasBeenSuccess ? background : errorImage}
                  alt="Background"
                />
              </div>
            </>
          )}
        </section>
      </Container>
    </>
  );
}
