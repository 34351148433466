import { SelectInputOption } from '../FormBuilder';
import i18next from 'i18next';

export const statusOptions: SelectInputOption[] = [
  { name: i18next.t('table.status'), value: '' },
  { name: i18next.t('status.CREATED'), value: 'CREATED' },
  { name: i18next.t('status.ISSUED'), value: 'ISSUED' },
  { name: i18next.t('status.REVOKED'), value: 'REVOKED' },
  {
    name: i18next.t('status.REVOCATION_REQUESTED'),
    value: 'REVOCATION_REQUESTED',
  },
  { name: i18next.t('status.VERIFIED'), value: 'VERIFIED' },
  { name: i18next.t('status.UNVERIFIED'), value: 'UNVERIFIED' },
  { name: i18next.t('status.ISSUE_REQUESTED'), value: 'ISSUE_REQUESTED' },
  { name: i18next.t('status.REVOCATION_FAILED'), value: 'REVOCATION_FAILED' },
];

export const credentialRequestsStatusOptions: SelectInputOption[] = [
  { name: i18next.t('table.status'), value: '' },
  { name: i18next.t('requestStatus.REQUESTED'), value: 'REQUESTED' },
  { name: i18next.t('requestStatus.APPROVED'), value: 'APPROVED' },
];
export const placeholders: Record<string, string> = {
  did: 'DID',
  email: 'Email',
  searchableText: i18next.t('filters.any'),
};

export const ACTION_NAME_OPTIONS: SelectInputOption[] = [
  { name: i18next.t('filters.actionName'), value: '' },
  { name: i18next.t('auditTrailsActions.UserLogin'), value: 'UserLogin' },
  {
    name: i18next.t('auditTrailsActions.CredentialOrderCreated'),
    value: 'CredentialOrderCreated',
  },
  {
    name: i18next.t('auditTrailsActions.ResendCredentialOrderEmail'),
    value: 'ResendCredentialOrderEmail',
  },
  {
    name: i18next.t('auditTrailsActions.CredentialHolderAuthenticated'),
    value: 'CredentialHolderAuthenticated',
  },
  {
    name: i18next.t('auditTrailsActions.CredentialIssued'),
    value: 'CredentialIssued',
  },
  {
    name: i18next.t('auditTrailsActions.CredentialRevocationRequested'),
    value: 'CredentialRevocationRequested',
  },
  {
    name: i18next.t('auditTrailsActions.CredentialRevoked'),
    value: 'CredentialRevoked',
  },
  {
    name: i18next.t('auditTrailsActions.CredentialRequestCreated'),
    value: 'CredentialRequestCreated',
  },
  {
    name: i18next.t('auditTrailsActions.CredentialRequestConfirmed'),
    value: 'CredentialRequestConfirmed',
  },
  {
    name: i18next.t('auditTrailsActions.CredentialRequestDocumentSigned'),
    value: 'CredentialRequestDocumentSigned',
  },
  {
    name: i18next.t('auditTrailsActions.PresentationCreated'),
    value: 'PresentationCreated',
  },
];
