import '../SharedEntitiesManagementStyles.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import List from '../../../components/List/List';
import ThemedButton from '../../../components/Buttons/ThemedButton';
import CreateEntityIcon from '../../../assets/icons/create-credential.svg';
import { useLocation, useNavigate, Location } from 'react-router-dom';
import { useEntitiesContext } from '../../../contexts/EntitiesContext';
import { Entity } from '../../../domain/entitiesManagementDtos';
import ActionsCell, {
  Action,
} from '../../../components/TableComponents/ActionsCell';
import EyeIcon from '../../../assets/icons/table-icons/eye.svg';
import PencilIcon from '../../../assets/icons/table-icons/pencil.svg';
import useEditForm from './useEditForm';
import { FRONTEND_PATHS } from '../../../config';

const TRANSLATION_PREFIX = 'entitiesManagementPage.entitiesManagementSection.';

function EntitiesManagement() {
  const [isListLoading, setIsListLoading] = useState<boolean>(true);
  const { entityRepo, setSelectedEntity, entities, setEntities } =
    useEntitiesContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation() as Location & { state: { entityId: string } };
  const { editFormModalJSX, setEntityToEdit, setIsEditModalOpened } =
    useEditForm();
  const COLUMNS = [
    {
      field: 'name',
      title: t(`${TRANSLATION_PREFIX}columns.alias`),
    },
    {
      field: 'vatNumber',
      title: t(`${TRANSLATION_PREFIX}columns.cif`),
      render: (data: Entity) =>
        data.vatNumber ? data.vatNumber : t('table.notAvailable'),
    },
    {
      field: '',
      title: '',
      render: (entity: Entity) => {
        const actions: Action[] = [
          {
            onClick: () => {
              const dynamicPath = FRONTEND_PATHS.ENTITY_DETAILS.replace(
                ':entityId',
                entity.id,
              );
              setSelectedEntity(entity);
              navigate(`${FRONTEND_PATHS.ENTITIES_MANAGEMENT}${dynamicPath}`);
            },
            labelId: `view-${entity.id}`,
            labelTitle: t('table.viewDetails'),
            icon: EyeIcon,
          },
          {
            onClick: () => {
              setEntityToEdit({ ...entity, vatNumber: entity.vatNumber ?? '' });
              setIsEditModalOpened(true);
            },
            labelId: `edit-${entity.id}`,
            labelTitle: t('actions.edit'),
            icon: PencilIcon,
          },
        ];
        return (
          <ActionsCell
            key={`entities-actions-${entity.id}`}
            actions={actions}
          />
        );
      },
    },
  ];

  useEffect(() => {
    entityRepo
      .getEntities()
      .then((response) => 'data' in response && setEntities(response.data))
      .finally(() => setIsListLoading(false));
  }, []);

  return (
    <section className="main-container">
      {editFormModalJSX}
      <section className="main-container_create-button">
        <ThemedButton
          text={t(`${TRANSLATION_PREFIX}buttonTitle`)}
          onClick={() =>
            navigate(
              `${FRONTEND_PATHS.ENTITIES_MANAGEMENT}${FRONTEND_PATHS.CREATE_ENTITY}`,
            )
          }
          icon={CreateEntityIcon}
          size="big"
        />
      </section>
      <List
        title={t(`${TRANSLATION_PREFIX}title`)}
        emptyMessage={t(`${TRANSLATION_PREFIX}emptyListMessage`)}
        columns={COLUMNS}
        rows={entities}
        isLoading={isListLoading}
        highlightElement={
          state ? (entity) => entity.id === state.entityId : undefined
        }
      />
    </section>
  );
}

export default EntitiesManagement;
