import './Breadcrumbs.css';
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import UrlPathHandler from '../../domain/urlPathHandler';
import { useTranslation } from 'react-i18next';
import { useEntitiesContext } from '../../contexts/EntitiesContext';
import { FRONTEND_PATHS } from '../../config';

function Breadcrumbs() {
  const { pathname } = useLocation();
  const paths = useMemo(
    () => new UrlPathHandler(pathname).getPaths(),
    [pathname],
  );
  const { t } = useTranslation();
  const { selectedEntity, selectedWallet } = useEntitiesContext();

  if (paths.length <= 1) {
    return null;
  }

  return (
    <section className="breadcrumb-section">
      {paths.map(({ key, path }, index, context) => {
        if (key.toLowerCase().includes('details')) {
          return null;
        }

        const entityName =
          selectedEntity?.id === key ? selectedEntity.name : undefined;
        const walletName =
          selectedWallet?.walletId === key ? selectedWallet.alias : undefined;
        const title = UrlPathHandler.isUuid(key)
          ? entityName ?? walletName ?? ''
          : t(`headers.${key}`);

        return (
          <div
            className="breadcrumb-wrapper"
            data-testid={`breadcrumb-${path}`}
            key={`breadcrumb-${path}`}
          >
            <Link
              to={`${FRONTEND_PATHS.ENTITIES_MANAGEMENT}${
                index === 0
                  ? ''
                  : UrlPathHandler.mapToFrontendPath(
                      path,
                      selectedEntity?.id,
                      entityName ? undefined : selectedWallet?.walletId,
                    )
              }`}
              className="breadcrumb-section_link"
            >
              <h3
                style={{ fontWeight: index === context.length - 1 ? 900 : '' }}
              >
                {title.toLowerCase()}
              </h3>
            </Link>
            {index !== context.length - 1 && (
              <h3 className="breadcrumb-section_separator">/</h3>
            )}
          </div>
        );
      })}
    </section>
  );
}

export default Breadcrumbs;
