import './TableComponents.css';
import React, { useContext, useMemo, useState } from 'react';
import FilterIcon from '../../assets/icons/table-icons/filter.svg';
import SearchIcon from '../../assets/icons/table-icons/search.svg';
import { convertToQueryString } from '../../pages/Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { CredentialsContext } from '../../contexts/CredentialsProviders/CredentialsContext';
import FormController from '../../domain/FormBuilder/FormController';
import { useTranslation } from 'react-i18next';
import refreshIcon from '../../assets/icons/table-icons/refresh.svg';
import { useGenerateModal } from '../Modal/Modal';

interface SearchBarProps {
  context: React.Context<CredentialsContext<any, any>>;
}

function TableActions({ context }: SearchBarProps) {
  const [rotationDegrees, setRotationDegrees] = useState(0);
  const [debounceTimeoutId, setDebounceTimeoutId] =
    useState<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();
  const { setFilters, filters, loadCredentials, tableSpecs } =
    useContext(context);
  const { FiltersModal, setIsFiltersModalOpened, isFiltersModalOpened } =
    useGenerateModal<'Filters'>('Filters');
  const cachedFilterState = useMemo(() => filters, [isFiltersModalOpened]);

  function debounceOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (debounceTimeoutId) {
      clearTimeout(debounceTimeoutId);
      setDebounceTimeoutId(null);
    }

    const updatedFilters = {
      ...filters,
      searchableText: event.target.value,
    };
    setFilters(updatedFilters);

    setDebounceTimeoutId(
      setTimeout(async () => {
        const queryParams = convertToQueryString(updatedFilters);
        await loadCredentials(tableSpecs.tableSize, 1, queryParams);
      }, 1500),
    );
  }

  async function handleRefresh() {
    setRotationDegrees(rotationDegrees - 360);
    const queryParams = convertToQueryString(filters);
    await loadCredentials(
      tableSpecs.tableSize,
      tableSpecs.currentPage,
      queryParams,
    );
  }

  return (
    <section className="filter-container">
      <div className="custom-searchbar">
        <img
          alt="Search icon"
          className="custom-searchbar_image"
          src={SearchIcon}
        />
        <input
          className="custom-searchbar_input"
          placeholder={t('actions.search')}
          value={filters.searchableText}
          onChange={debounceOnChange}
        />
      </div>
      <button
        className="filter-container_button"
        onClick={() => setIsFiltersModalOpened(true)}
      >
        <img
          alt="Advanced filter icon"
          className="filter-container_button__image"
          src={FilterIcon}
        />
      </button>
      <button className="filter-container_button" onClick={handleRefresh}>
        <img
          src={refreshIcon}
          alt="Refresh icon"
          className="filter-container_button__image"
          style={{ transform: `rotate(${rotationDegrees}deg)` }}
        />
      </button>
      <FiltersModal
        title={t('filters.title')}
        // TODO: REMOVE DUPLICATE LOGIC
        options={{ onClose: () => setFilters(cachedFilterState) }}
      >
        <FormController
          context={context}
          closeModal={() => setIsFiltersModalOpened(false)}
        />
      </FiltersModal>
    </section>
  );
}

export default TableActions;
