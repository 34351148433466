import './Modal.css';
import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import CrossIcon from '../../assets/icons/table-icons/revoke.svg';

interface ModalProps {
  isOpened: boolean;
  onClose: () => void;
  title: string;
}

interface GeneratedModalProps {
  title: string;
  options?: {
    onClose?: () => void;
  };
}

type ModalState<ModalName extends string> = {
  [K in `is${ModalName}ModalOpened`]: boolean;
} & {
  [K in `setIs${ModalName}ModalOpened`]: React.Dispatch<
    React.SetStateAction<boolean>
  >;
} & {
  [K in `${ModalName}Modal`]: ({
    title,
  }: PropsWithChildren<GeneratedModalProps>) => JSX.Element;
};

function Modal({
  isOpened,
  onClose,
  title,
  children,
}: PropsWithChildren<ModalProps>) {
  if (!isOpened) {
    return null;
  }

  return (
    <main className="modal-background">
      <section className="modal-container">
        <h2>{title}</h2>
        <button className="modal-container_close-button" onClick={onClose}>
          <img src={CrossIcon} alt="Close modal icon" />
        </button>
        {children}
      </section>
    </main>
  );
}

export function useGenerateModal<Name extends string>(
  modalName: Name,
): ModalState<Name> {
  const [isOpened, setIsOpened] = useState(false);
  const GeneratedModal = useCallback(
    ({ title, options, children }: PropsWithChildren<GeneratedModalProps>) => {
      return (
        <Modal
          isOpened={isOpened}
          title={title}
          onClose={() => {
            options?.onClose && options.onClose();
            setIsOpened(false);
          }}
        >
          {children}
        </Modal>
      );
    },
    [isOpened],
  );

  return useMemo(
    () =>
      ({
        [`is${modalName}ModalOpened`]: isOpened,
        [`setIs${modalName}ModalOpened`]: setIsOpened,
        [`${modalName}Modal`]: GeneratedModal,
      } as ModalState<Name>),
    [isOpened, modalName, GeneratedModal],
  );
}
