import React from 'react';
import { useMemo, useState } from 'react';
import { FormBuilder } from '../../../domain/FormBuilder/FormBuilder';
import { Wallet } from '../../../domain/entitiesManagementDtos';
import { useTranslation } from 'react-i18next';
import Banner from '../../../components/Banner/Banner';
import { useGenerateModal } from '../../../components/Modal/Modal';
import { useEntitiesContext } from '../../../contexts/EntitiesContext';

interface UseEditFormReturn {
  editFormModalJSX: JSX.Element;
  setWalletToEdit: React.Dispatch<
    React.SetStateAction<Omit<Wallet, 'keyManagementServiceId' | 'dids'>>
  >;
  setIsEditModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

function useEditForm(): UseEditFormReturn {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setWallets, walletRepo } = useEntitiesContext();
  const { setIsEditModalOpened, isEditModalOpened, EditModal } =
    useGenerateModal<'Edit'>('Edit');
  const { t } = useTranslation();
  const [walletToEdit, setWalletToEdit] = useState<
    Omit<Wallet, 'keyManagementServiceId' | 'dids'>
  >({
    walletId: '',
    alias: '',
  });
  const initialWalletValues = useMemo(() => walletToEdit, [isEditModalOpened]);

  function handleChange(event: { target: HTMLInputElement }) {
    const { name, value } = event.target;
    setWalletToEdit({
      ...walletToEdit,
      [name]: value.trimStart(),
    });
  }

  async function handleClick() {
    setIsLoading(true);
    const response = await walletRepo.editAlias(
      walletToEdit.walletId,
      walletToEdit.alias,
    );
    setIsEditModalOpened(false);
    setIsLoading(false);
    if ('error' in response) {
      Banner.show(t('errors.editWalletAlias'), true);
      return;
    }
    setWallets((prevState) => {
      const walletsCopy = [...prevState];
      const editedWalletIndex = prevState.findIndex(
        (wallet) => wallet.walletId === walletToEdit.walletId,
      );
      walletsCopy.splice(editedWalletIndex, 1, {
        ...walletsCopy[editedWalletIndex],
        ...walletToEdit,
      });
      return walletsCopy;
    });
    Banner.show(t('successes.editWalletAlias'));
  }

  const editFormJSX = useMemo(() => {
    const formBuilder = new FormBuilder();
    formBuilder.addInput('text', walletToEdit.alias, 'alias', handleChange);
    formBuilder.addButton(t('actions.save'), handleClick, {
      isLoading,
      isDisabled: walletToEdit.alias === initialWalletValues.alias,
    });
    return formBuilder.getJSX();
  }, [walletToEdit, t, isLoading]);

  return {
    editFormModalJSX: (
      <EditModal
        title={t('entitiesManagementPage.entityDetails.editWalletAlias')}
      >
        {editFormJSX}
      </EditModal>
    ),
    setIsEditModalOpened,
    setWalletToEdit,
  };
}

export default useEditForm;
