import '../SharedEntitiesManagementStyles.css';
import React, { useEffect } from 'react';
import List from '../../../components/List/List';
import { useTranslation } from 'react-i18next';
import BackButton from '../../../components/Buttons/BackButton';
import { FRONTEND_PATHS } from '../../../config';
import { useNavigate, useParams } from 'react-router-dom';
import ThemedButton from '../../../components/Buttons/ThemedButton';
import useDidDetails from './useDidDetails';
import useEditDidAlias from './useEditDidAlias';
import useDeleteDid from './useDeleteDid';
import { useEntitiesContext } from '../../../contexts/EntitiesContext';
import CreateWalletIcon from '../../../assets/icons/create-credential.svg';
import useListColumns from './useListColumns';
import UrlPathHandler from '../../../domain/urlPathHandler';

const TRANSLATION_PREFIX = 'entitiesManagementPage.walletDetails.';

function WalletDetails() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { entityId, walletId } = useParams();
  const { entities, selectedWallet, getWallets } = useEntitiesContext();
  const { onOpenDidDetailsModal, DidDetailsModal } = useDidDetails();
  const { setDidToEdit, setIsEditDidAliasModalOpened, editAliasModalJSX } =
    useEditDidAlias();
  const { setIsDeleteDidModalOpened, setDidToDelete, deleteModalJSX } =
    useDeleteDid();
  const COLUMNS = useListColumns({
    onOpenDidDetailsModal,
    setDidToEdit,
    setDidToDelete,
    setIsEditDidAliasModalOpened,
    setIsDeleteDidModalOpened,
  });

  useEffect(() => {
    if (
      !entityId ||
      !walletId ||
      !UrlPathHandler.isUuid(entityId) ||
      !UrlPathHandler.isUuid(walletId)
    ) {
      navigate(`${FRONTEND_PATHS.ENTITIES_MANAGEMENT}`);
      return;
    }
    getWallets(entityId, walletId);
  }, [entities]);

  return (
    <section className="main-container" style={{ paddingTop: '40px' }}>
      <DidDetailsModal />
      {editAliasModalJSX}
      {deleteModalJSX}
      <BackButton
        destinationRoute={`${
          FRONTEND_PATHS.ENTITIES_MANAGEMENT
        }${FRONTEND_PATHS.ENTITY_DETAILS.replace(':entityId', entityId ?? '')}`}
      />
      <section className="main-container_create-button">
        <ThemedButton
          text={t(`${TRANSLATION_PREFIX}buttonTitle`)}
          onClick={() => {
            const dynamicPath = FRONTEND_PATHS.CREATE_DID.replace(
              ':entityId',
              entityId ?? '',
            ).replace(':walletId', walletId ?? '');
            navigate(`${FRONTEND_PATHS.ENTITIES_MANAGEMENT}${dynamicPath}`);
          }}
          icon={CreateWalletIcon}
          size="big"
        />
      </section>
      <List
        title={t(`${TRANSLATION_PREFIX}title`, {
          walletName: selectedWallet?.alias,
        })}
        emptyMessage={t(`${TRANSLATION_PREFIX}emptyListMessage`)}
        columns={COLUMNS}
        rows={selectedWallet ? selectedWallet.dids : []}
        isLoading={false}
      />
    </section>
  );
}

export default WalletDetails;
