import './SharedEntitiesManagementStyles.css';
import React, { useEffect, useState } from 'react';
import BackButton from '../../components/Buttons/BackButton';
import { FRONTEND_PATHS } from '../../config';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CreateDidRequestBody,
  DidMethods,
  URL_REGEX,
} from '../../domain/entitiesManagementDtos';
import { useTranslation } from 'react-i18next';
import Stepper, { Step } from '../../components/Stepper/Stepper';
import FirstStep from '../../components/CreateDidSteps/FirstStep';
import SecondStep from '../../components/CreateDidSteps/SecondStep';
import Summary from '../../components/Summary/Summary';
import Banner from '../../components/Banner/Banner';
import { useEntitiesContext } from '../../contexts/EntitiesContext';
import CreateDidSuccess from './CreateDidSuccess/CreateDidSuccess';
import UrlPathHandler from '../../domain/urlPathHandler';

function CreateDid() {
  const [isLastStep, setIsLastStep] = useState<boolean>(false);
  const [isCreated, setIsCreated] = useState<boolean>(false);
  const [did, setDid] = useState<CreateDidRequestBody>({
    alias: '',
    method: DidMethods.key,
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { entityId, walletId } = useParams();
  const { entities, didRepo, getWallets } = useEntitiesContext();

  const ENTRIES = [
    {
      value: did.alias,
      label: t('entitiesManagementPage.createDid.alias'),
    },
    {
      value: did.method,
      label: t('entitiesManagementPage.createDid.method'),
    },
  ];
  did.opts?.url && ENTRIES.push({ value: did.opts.url, label: 'URL' });

  const STEPS: Step[] = [
    {
      title: t('entitiesManagementPage.createDid.steps.first'),
      renderStep: () => <FirstStep alias={did.alias} setDid={setDid} />,
      isNextDisabled: !did.alias,
    },
    {
      title: t('entitiesManagementPage.createDid.steps.second'),
      renderStep: () => (
        <SecondStep method={did.method} url={did.opts?.url} setDid={setDid} />
      ),
      isNextDisabled:
        did.opts?.url === undefined ? false : !URL_REGEX.test(did.opts.url),
      onNextCLicked: () => {
        setIsLastStep(true);
        return true;
      },
    },
  ];

  async function onCreate() {
    const response = await didRepo.createDid(walletId ?? '', did);
    if ('error' in response) {
      Banner.show(t('errors.createDid'), true);
      return;
    }
    Banner.show(t('successes.createDid'));
    setIsCreated(true);
  }

  useEffect(() => {
    if (
      !entityId ||
      !walletId ||
      !UrlPathHandler.isUuid(entityId) ||
      !UrlPathHandler.isUuid(walletId)
    ) {
      navigate(`${FRONTEND_PATHS.ENTITIES_MANAGEMENT}`);
      return;
    }
    getWallets(entityId, walletId);
  }, [entities]);

  return (
    <section
      style={{
        position: 'relative',
        display: 'flex',
        height: '80%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '30px',
      }}
    >
      {isCreated ? (
        <CreateDidSuccess
          did={did}
          entityId={entityId ?? ''}
          walletId={walletId ?? ''}
        />
      ) : (
        <>
          <BackButton
            destinationRoute={`${
              FRONTEND_PATHS.ENTITIES_MANAGEMENT
            }${FRONTEND_PATHS.WALLET_DETAILS.replace(
              ':entityId',
              entityId ?? '',
            ).replace(':walletId', walletId ?? '')}`}
          />
          <section
            style={{ display: isLastStep ? '' : 'none', height: '100%' }}
          >
            <Summary
              summaryTitle={'DID Summary'}
              onCreate={onCreate}
              onClickBack={() => setIsLastStep(false)}
              entries={ENTRIES}
            />
          </section>
          <section
            style={{ display: isLastStep ? 'none' : '', height: '100%' }}
          >
            <Stepper steps={STEPS} />
          </section>
        </>
      )}
    </section>
  );
}

export default CreateDid;
