export default {
  invalidUserRolException: "Rol d'usuari invàlid",
  roleEntityException: "Rol d'usuari invàlid",
  roleIssuerException: "Rol d'usuari invàlid",
  invalidUuidFormatException: "Error de format a l'uuid",
  invalidISOFormatException: 'Error de format a la data',
  invalidEmailFormatException: "Error de format a l'email",
  InvalidLevelOfAssuranceException: 'Nivell de garantia no suportat',
  InvalidFieldPathException: 'Ruta de camp invàlida',
  InvalidCredentialSubjectValue:
    "La propietat de l'assumpte de la credencial proporcionada no coincideix amb la sol·licitada",
  InvalidCredentialSubjectProperty:
    "No es troba la propietat a l'assumpte de la credencial",
  DidNotFoundInJwt: 'DID no trobat al JWT',
  InternalServerCustomError: 'Error intern',
  CredentialIdNotFoundException: 'Ordre de credencial no trobada',
  CredentialOrderIdNotFoundException: 'Ordre de credencial no trobada',
  CredentialOrdersByFilterNotFoundException:
    'No hi ha credencials que coincideixin amb la seva cerca',
  CredentialOrderNotFoundException: "No s'ha trobat cap ordre de credencial",
  AuthServiceValidationException: "Accés d'entitat no autoritzat",
  CredentialDuplicatedException: 'La credencial ja ha sigut emesa anteriorment',
  CredentialPayloadValidatorException:
    'Error validant el contingut de la credencial',
  EntityDidNotValidException: "DID de l'entitat invàlid",
  frontend_error_000: "Error autenticant l'usuari: ",
  frontend_error_001: "Error obtenint l'ordre de credencial: ",
  frontend_error_002: 'Error obtenint les credencials',
  frontend_error_003: 'Error en emetre la credencial: ',
  frontend_error_004: 'Error obtenint els tipus de les credencials: ',
  frontend_error_005: 'Error obtenint els nivells de garantia: ',
  frontend_error_006: 'Error obtenint el nivell de garantia: ',
  frontend_error_007: 'Error obtenint les entitats',
  frontend_error_008: "Error en reenviar l'email",
  errors: {
    getEntityDetails:
      "Els detalls de l'entitat no s'han pogut carregar. Intenti-ho de nou.",
    createEntity: "L'entitat no s'ha pogut crear. Intenti-ho de nou.",
    editEntity: "L'entitat no s'ha pogut modificar. Intenti-ho de nou.",
    getWalletDetails:
      "Els detalls del wallet no s'han pogut carregar. Intenti-ho de nou.",
    createWallet: "El wallet no s'ha pogut crear. Intenti-ho de nou.",
    deleteWallet: "El wallet no s'ha pogut esborrar. Intenti-ho de nou.",
    editWalletAlias:
      "L'àlies del wallet no s'ha pogut modificar. Intenti-ho de nou.",
    getKeyStorages:
      "Els key storages no s'han pogut carregar. Intenti-ho de nou.",
    didDocument: "El document DID no s'ha pogut carregar. Intenti-ho de nou.",
    createDid: "El DID no s'ha pogut crear. Intenti-ho de nou.",
    editDidAlias: "L'àlies del DID no s'ha pogut modificar. Intenti-ho de nou.",
    deleteDid: "El DID no s'ha pogut esborrar. Intenti-ho de nou.",
    activateDid: "El DID no s'ha pogut activar. Intenti-ho de nou.",
    invalidUrl: 'URL invàlid',
  },
  successes: {
    createEntity: "L'entitat ha estat creada amb èxit.",
    editEntity: "L'entitat ha estat modificada amb èxit.",
    createWallet: 'El wallet ha estat creat amb èxit.',
    deleteWallet: 'El wallet ha estat esborrat amb èxit.',
    editWalletAlias: "L'àlies del wallet ha estat modificat amb èxit.",
    createDid: 'El DID ha estat creat amb èxit.',
    editDidAlias: "L'àlies del DID ha estat modificat amb èxit.",
    deleteDID: 'El DID ha estat esborrat amb èxit.',
    activateDid:
      'El DID ha estat activat amb èxit. Veurà els canvis en uns instants.',
  },
  login: {
    control:
      'Controli i emeti credencials verificables a qualsevol moment i qualsevol lloc',
    with_vidchain:
      "Amb VIDidentity oferim solucions d'identitat segura que permeten una privacitat, drets i llibertats a les persones d'una manera més digital i sostenible.",
    login_with: 'Iniciï sessió amb VIDwallet',
    more_info: 'Més info sobre VIDwallet',
    successful: "S'ha iniciat sessió amb èxit",
    failed: "No s'ha pogut iniciar sessió",
    redirected: "Serà redirigit a la pantalla d'inici",
    try_again:
      "Si us plau, torni-ho a provar o contacti amb l'administrador de la seva plataforma.",
    continueIssuance:
      "Feu clic al botó d'inici de sessió per continuar amb l'emissió de la credencial",
    redirecting: "Redirigint per a l'autenticació…",
    get_it: 'Aconsegueixi-la gratuïtament al teu telèfon',
  },
  callback: {
    success: "La credencial s'ha emès amb èxit",
    mobile_wallet:
      'Credencial emesa. Obri el VIDwallet per rebre la credencial',
    go_wallet: 'Pot trobar la seva credencial al seu wallet.',
    open_wallet: 'Obrir VIDwallet',
    failed: "El procés d'emissió de la credencial ha fallat",
    error_details: "Detalls de l'error:",
  },
  disclaimers: {
    logout: 'Està a punt de tancar la sessió.\nEstà segur?',
  },
  actions: {
    cancel: 'Cancel·lar',
    filter: 'Filtrar',
    accept: 'Acceptar',
    success: 'Èxit',
    confirm: 'Confirmar',
    search: 'Buscar',
    create: 'Crear',
    revoke: 'Revocar',
    resend: 'Reenviar',
    edit: 'Editar',
    close: 'Tancar',
    save: 'Guardar',
    approve: 'Aprovar',
    reject: 'Rebutjar',
    sendToSign: 'Enviar a firmar',
    ok: 'OK',
    back: 'Enrere',
    delete: 'Esborrar',
    logout: 'Tancar sessió',
    copy: 'Copiar',
    copied: 'Copiat',
    download: 'Descarregar',
    continue: 'Continuar',
  },
  form: {
    fill: 'Completi el formulari',
    receiverEmail: 'Email del receptor',
    identityVerification: "Mètode de verificació d'identitat",
    loadingCredential: 'Carregant tipus de la credencial…',
    loadingIdentity: "Carregant dades de verificació d'identitat…",
    createNewUser: 'Crear nou usuari',
    fillfield: "Ha d'omplir aquest camp",
    provideValidEmail: 'Ha de proveir un email vàlid',
    provideValidNumber: 'Hs de proveir un número personal vàlid',
    provideValidURL: 'Ha de proveir una URL vàlida',
    provideValidDate: 'Ha de proveir una data vàlida',
    credentialCreated:
      "Ordre de credencial creada amb èxit. L'usuari rebrà un correu electrònic per obtenir la credencial",
    credentialF2FCreated:
      'Ordre de credencial creada amb èxit. Si us plau, firmi el document al dispositiu %%%%% per finalitzar el procés',
    credentialSubject: 'Assumpte de la credencial',
    language: 'Idioma',
    chooseLanguage: 'Seleccioni un idioma…',
    credentialUpdated: 'Sol·licitud de credencial actualitzada amb èxit',
    userLanguage: "Idioma de l'usuari",
  },
  language: {
    spanish: 'Espanyol',
    english: 'Anglès',
  },
  filters: {
    title: 'Filtres',
    any: 'Qualsevol atribut a la credencial',
    credentialType: 'Tipus de credencial',
    startDate: 'Data inicial',
    endDate: 'Data final',
    actionName: "Nom de l'acció",
    entityName: "Nom de l'entitat",
    selectEntity: 'Selecciona una entitat',
    searchText: 'Text de cerca',
    types: 'Tipus',
    status: 'Estat',
  },
  table: {
    creationDate: 'Data de creació',
    notAvailable: 'N/A',
    empty: 'Cap registre a mostrar',
    sureRevoke: 'Està segur que vol revocar aquesta credencial?',
    sureReject:
      'Està segur que vol rebutjar aquesta sol·licitud de credencial?',
    agentName: "Nom d'agent",
    date: 'Data',
    status: 'Estat',
    viewDetails: 'Veure detalls',
    revokeCredential: 'Revocar credencial',
    resendEmail: 'Reenviar correu',
    ivmStatus: "Estat de verificació d'identitat",
    expirationDate: "Data d'expiració",
    rowsPerPage: 'Files per pàgina:',
    of: 'de',
    more: 'més de',
  },
  menu: {
    credentials: 'Gestió de credencials',
    issueCredentials: 'Emetre credencials',
    user: "Gestió d'usuari",
    auditTrails: 'Auditoria',
    credentialRequests: 'Sol·licituds de credencial',
    entitiesManagement: "Gestió d'entitats",
  },
  details: {
    attachments: 'Adjunts',
    auditTrailsDetails: "Detalls de l'auditoria",
    name: 'Nom',
    eventName: "Nom de l'esdeveniment",
    operatorName: "Nom de l'agent",
    entity: 'Entitat',
    operator: 'Agent',
    role: 'Rol',
    legalId: 'Identificador legal',
    action: 'Acció',
    payload: 'Contingut',
    emailResentSuccess: "El correu s'ha reenviat amb èxit",
    revokeSuccess: 'Credencial revocada amb èxit',
    rejectSuccess: 'Sol·licitud de credencial rebutjada',
    credentialDetails: 'Detalls de la credencial',
    userDetails: "Detalls de l'usuari",
    requestDetails: 'Detalls de sol·licitud',
    credentialRequestDetails: 'Sol·licitud de credencial',
    credentialOrderId: "ID de l'Ordre de credencial",
    credentialIssuanceId: "ID d'emissió de credencial",
    type: 'Tipus de credencial',
    types: 'Tipus',
    issuer: 'Emissor',
    holder: 'Posseïdor',
    createdAt: 'Creada',
    issuedAt: 'Emesa',
    id: 'ID',
    test: 'Prova',
    receiverEmail: 'Email del receptor',
    identityVerificationMethod: "Mètode de verificació d'identitat",
    faceToFace: 'Presencial',
    next: 'Següent',
    previous: 'Anterior',
    stillNotSigned:
      "El document encara no ha sigut firmat. Firmi el document al dispositiu i feu clic a 'Següent'.",
    credentialIssuanceDocumentSignature:
      "Firmi del document de la sol·licitud d'emissió de la credencial",
    selectDevice:
      'Seleccioni un dispositiu al qual enviar el document a firmar:',
    signedDocument: "S'ha enviat un email a l'usuari per finalitzar el procés",
    docHasBeenSent: "Firmi el document al dispositiu i feu clic a 'Següent'.",
    docHasBeenRejected: 'El document ha sigut rebutjat',
    sent: 'Document enviat',
    signed: 'Document firmat',
    credentialOrderCreatedFromRequest:
      'La seva sol·licitud de credencial ha sigut aprovada. Aneu al llistat de credencials.',
    credentialRequestApproved: 'Sol·licitud de credencial aprovada',
  },
  requestDetails: {
    credentialRequestId: 'ID de sol·licitud de credencial',
    type: 'Tipus de credencial',
    receiverEmail: 'Email del receptor',
    requestedAt: 'Sol·licitada',
    identityVerificationMethod: "Mètode de verificació d'identitat",
    F2F: 'Presencial',
    userDetails: "Detalls de l'usuari",
    EmailCredential: "Credencial d'email",
    VidKycCredential: 'KYC',
  },
  identityVerificationMethod: {
    userName: 'Nom i cognoms',
    userLegalId: "Número d'Identitat",
  },
  status: {
    CREATED: 'Creat',
    ISSUED: 'Emès',
    REVOKED: 'Revocat',
    REVOCATION_REQUESTED: 'Revocació sol·licitada',
    undefined: 'Indefinit',
    REQUESTED: 'Sol·licitat',
    VERIFIED: 'Verificat',
    UNVERIFIED: 'No verificat',
    ISSUE_REQUESTED: 'Emissió sol·licitada',
    REVOCATION_FAILED: 'Revocació fallida',
  },
  requestStatus: {
    REQUESTED: 'Sol·licitat',
    APPROVED: 'Aprovada',
  },
  identityVerificationStatus: {
    CREATED: 'Creat',
    STARTED: 'Iniciat',
    DOCUMENT_REJECTED: 'Document rebutjat',
    DOCUMENT_SENT: 'Document enviat',
    FINISHED: 'Finalitzat',
  },
  auditTrailsActions: {
    UserLogin: 'Inici de sessió',
    CredentialOrderCreated: 'Ordre de credencial creada',
    ResendCredentialOrderEmail: 'Correu de sol·licitud de credencial reenviat',
    CredentialHolderAuthenticated: 'Receptor de la credencial autenticat',
    CredentialIssued: 'Credencial emesa',
    CredentialRevocationRequested: 'Revocació de credencial sol·licitada',
    CredentialRevoked: 'Credencial revocada',
    CredentialRequestCreated: 'Sol·licitud de credencial creada',
    CredentialOrderRequestCreated: "Sol·licitud d'ordre de credencial creada",
    CredentialRequestConfirmed: 'Sol·licitud de credencial confirmada',
    CredentialRequestDocumentSigned:
      'Document de la sol·licitud de credencial firmat',
    CredentialOrderRequestDeleted: 'Sol·licitud de credencial eliminada',
    PresentationCreated: 'Presentació creada',
  },
  headers: {
    credentials: 'Gestió de credencials',
    issueCredentials: 'Emetre credencials',
    userCredentials: "Gestió d'usuari",
    auditTrails: 'Auditoria',
    credentialRequests: 'Sol·licituds de credencial',
    entitiesManagement: "Gestió d'entitats",
    createEntity: 'Crear entitat',
    entityDetails: "Detalls de l'entitat",
    createWallet: 'Crear wallet',
    walletDetails: 'Detalls del wallet',
    createDid: 'Crear DID',
    new: 'Nou',
  },
  entitiesManagementPage: {
    entitiesManagementSection: {
      title: 'Entitats',
      buttonTitle: 'Crear entitat',
      emptyListMessage: 'Cap entitat registrada.',
      columns: {
        alias: 'Àlies',
        cif: 'CIF/Identificador',
      },
      editEntity: 'Editar entitat',
    },
    createEntity: {
      name: "Nom de l'entitat",
      cif: 'CIF/Identificador',
    },
    entityDetails: {
      title: 'Llista de wallets del {{entityName}}',
      emptyListMessage: 'Cap wallet registrada.',
      buttonTitle: 'Crear wallet',
      columns: {
        alias: 'Àlies',
        dids: 'DIDs',
      },
      deleteWallet: {
        title: 'Esborrar wallet',
        disclaimer:
          "Està a punt d'esborrar aquest wallet.\nEstà segur que vol esborrar-lo?",
      },
      editWalletAlias: 'Editar àlies',
    },
    createWallet: {
      alias: 'Àlies del wallet',
      keyStorageDescription: 'Escolleixi com emmagatzemarà les seves claus',
      summary: 'Resum del wallet',
      steps: {
        first: 'Crear un àlies',
        second: 'Seleccionar un key storage',
      },
    },
    walletDetails: {
      title: 'Llista de DIDs del {{walletName}}',
      emptyListMessage: 'Cap DID registrat.',
      buttonTitle: 'Crear DID',
      validateUrl: 'Validar URL',
      didDetails: 'Detalls del DID',
      columns: {
        alias: 'Àlies',
        method: 'Mètode DID',
        value: 'Valor',
      },
      deleteDid: {
        title: 'Esborrar DID',
        disclaimer:
          "Està a punt d'esborrar aquest DID.\nEstà segur que vol esborrar-lo?",
      },
      editDidAlias: 'Editar àlies',
    },
    createDid: {
      alias: 'Àlies del DID',
      method: 'Mètode del DID',
      didWebDescription:
        'És necessari proveir un URL que contingui el document DID per poder generar un did:web',
      didWebMessage:
        "El DID no es generarà fins que l'URL proporcionada sigui validada.\nDescarregui el document DID i pugi'l a l'URL per completar el procés.",
      success:
        'El DID ha estat creat amb èxit. És possible que no es reflecteixi immediatament, ja que el procés pot trigar uns minuts.',
      steps: {
        first: 'Crear un àlies',
        third: 'Seleccionar un DID',
      },
    },
  },
};
