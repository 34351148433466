import './Avatar.css';
import React from 'react';

interface AvatarProps {
  role: string;
  fullName: string;
}

const BACKGROUND = {
  Superadmin: 'var(--gold)',
  Admin: 'var(--primary)',
  Operator: 'var(--primary-light)',
};

function Avatar({ role, fullName }: AvatarProps) {
  const [firstName, lastName] = fullName.split(' ');

  return (
    <div
      className="avatar-container"
      style={{ backgroundColor: BACKGROUND[role as keyof typeof BACKGROUND] }}
    >
      {firstName && <h3>{firstName[0].toUpperCase()}</h3>}
      {lastName && <h3>{lastName[0].toUpperCase()}</h3>}
    </div>
  );
}

export default Avatar;
