import './FormBuilder.css';
import React, { HTMLInputTypeAttribute } from 'react';
import ThemedButton from '../../components/Buttons/ThemedButton';
import ThemedInput, {
  InputOptions,
} from '../../components/ThemedInput/ThemedInput';

export interface SelectInputOption {
  name: string;
  value: any;
}

export class FormBuilder {
  private readonly inputEntries: JSX.Element[];
  private readonly buttonEntries: JSX.Element[];
  private isLastStepCalled: boolean;

  constructor() {
    this.inputEntries = [];
    this.buttonEntries = [];
    this.isLastStepCalled = false;
  }

  addInput(
    type: HTMLInputTypeAttribute,
    value: string,
    name: string,
    onChange: React.ChangeEventHandler<any>,
    inputOptions: InputOptions = { selectInputOptions: [] },
  ) {
    if (this.isLastStepCalled) {
      throw Error("Can't add entry after button has been inserted ");
    }

    this.inputEntries.push(
      <ThemedInput
        key={`form-input-${name}-${this.inputEntries.length}`}
        type={type}
        value={value}
        name={name}
        onChange={onChange}
        inputOptions={inputOptions}
      />,
    );
  }

  addButton(
    text: string,
    onClick: React.MouseEventHandler<HTMLButtonElement>,
    options?: {
      background?: 'gray';
      isDisabled?: boolean;
      isLoading?: boolean;
    },
  ) {
    this.isLastStepCalled = true;
    this.buttonEntries.push(
      <ThemedButton
        key={`form-button-${text}-${this.inputEntries.length}`}
        text={text}
        onClick={onClick}
        background={options?.background}
        isDisabled={options?.isDisabled}
        isLoading={options?.isLoading}
      />,
    );
  }

  getJSX(): JSX.Element {
    if (!this.isLastStepCalled) {
      throw Error('addButton has not been called');
    }
    return (
      <form className="custom-form">
        {this.inputEntries}
        <section className="custom-form_buttons-section">
          {this.buttonEntries}
        </section>
      </form>
    );
  }
}
