import './SharedButtonStyles.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ArrowIcon from '../../assets/icons/navigation-icons/arrow-black.svg';

interface BackButtonProps {
  destinationRoute: string;
  state?: any;
}

function BackButton({ destinationRoute, state }: BackButtonProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <button
      type="button"
      onClick={() => navigate(destinationRoute, { state })}
      className="back-button"
    >
      <img src={ArrowIcon} alt="Back icon" />
      <h3>{t('actions.back')}</h3>
    </button>
  );
}

export default BackButton;
