import { useParams } from 'react-router-dom';
import './EmailLogin.css';
import spinner from '../../assets/spinners/spinner.gif';
import { OpenIDClient } from '../../services/openIDClient';
import { getLoATypeByCredentialRequestId } from '../../services/vidcredentials';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function EmailLogin() {
  const { t } = useTranslation();
  const { credentialRequestId } = useParams();

  const loginCredentialReceiverWithOidc = async () => {
    if (!credentialRequestId) {
      console.log('Error credential request id not found');
    }
    localStorage.setItem('credentialRequestId', credentialRequestId ?? '');
    if (!credentialRequestId) return;
    const client = OpenIDClient.getInstance().getClient();
    client.wipeTokens();
    await client.callback();
    const loAType = await getLoATypeByCredentialRequestId(credentialRequestId);
    const request = ['DidAuth', 'F2F'].includes(loAType)
      ? ['openid']
      : ['openid', loAType];
    const token = await client.getToken({
      scopes: {
        request,
      },
    });
  };

  useEffect(() => {
    loginCredentialReceiverWithOidc();
  }, []);

  return (
    <div className="blue-container">
      <img src={spinner} alt="Spinner" />
      <h3>{t('login.redirecting')}</h3>
    </div>
  );
}
