import '../Shared.css';
import React, { useContext } from 'react';
import FilterTag from '../../components/FilterTag/FilterTag';
import { CredentialRequestsContext } from '../../contexts/CredentialsProviders/Providers/CredentialRequestsContext';
import { CredentialRequestsTable } from './CredentialRequestsTable/CredentialRequestsTable';
import { useRouteSecurization } from '../../hooks/useRouteSecurization';
import { CredentialsFilters } from '../../contexts/CredentialsProviders/Providers/AllCredentialsContext';
import { convertToQueryString } from '../Credentials/CredentialDetailsModal/helpers/StringHelpers';
import TableActions from '../../components/TableComponents/TableActions';
import { useTranslation } from 'react-i18next';
import { useAccessToken } from '../../hooks/useRefreshToken';

export function CredentialRequests() {
  useRouteSecurization(['admin', 'operator']);
  const { t } = useTranslation();
  const {
    loadSelectCredentialTypes,
    filters,
    loadCredentials,
    tableSpecs,
    setFilters,
  } = useContext(CredentialRequestsContext);
  const { email, types, searchableText, status } = filters;

  const onRemoveTag = async (filterKey: keyof CredentialsFilters) => {
    const updatedFilter = { ...filters, [filterKey]: '' };
    const queryParams = convertToQueryString(updatedFilter);
    setFilters(updatedFilter);
    await loadCredentials(tableSpecs.tableSize, 1, queryParams);
  };

  const getInitialData = async () => {
    await loadCredentials(10, 1);
    await loadSelectCredentialTypes();
  };

  useAccessToken(getInitialData);

  return (
    <div className="credentials-container">
      <TableActions context={CredentialRequestsContext} />
      <div className="filter-list-container">
        {types && (
          <FilterTag
            filter={`${t('details.types')}: ${types}`}
            onClose={() => onRemoveTag('types')}
          />
        )}
        {email && (
          <FilterTag
            filter={`Email: ${email}`}
            onClose={() => onRemoveTag('email')}
          />
        )}
        {searchableText && (
          <FilterTag
            filter={`${t('filters.searchText')}: ${searchableText}`}
            onClose={() => onRemoveTag('searchableText')}
          />
        )}
        {status && (
          <FilterTag
            filter={`${t('table.status')}: ${t(`requestStatus.${status}`)}`}
            onClose={() => onRemoveTag('status')}
          />
        )}
      </div>
      <CredentialRequestsTable />
    </div>
  );
}
