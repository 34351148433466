import React from 'react';
import { useTranslation } from 'react-i18next';
import ThemedInput from '../ThemedInput/ThemedInput';
import { CreateDidRequestBody } from '../../domain/entitiesManagementDtos';

interface FirstStepProps {
  alias: string;
  setDid: React.Dispatch<React.SetStateAction<CreateDidRequestBody>>;
}

function FirstStep({ alias, setDid }: FirstStepProps) {
  const { t } = useTranslation();
  return (
    <ThemedInput
      type="text"
      value={alias}
      name="alias"
      onChange={(e) =>
        setDid((prevState) => ({ ...prevState, alias: e.target.value }))
      }
      inputOptions={{ label: t('entitiesManagementPage.createDid.alias') }}
    />
  );
}

export default FirstStep;
