import { useState } from 'react';
import CredentialOrder from '../../components/CredentialOrder/CredentialOrder';
import { Button } from 'reactstrap';

export function IssueCredentialOrder() {
  const [issue, setIssue] = useState(false);

  return (
    <div className="blue-container">
      {issue ? (
        <CredentialOrder />
      ) : (
        <Button
          onClick={() => {
            setIssue(true);
          }}
        >
          Obtain Credential
        </Button>
      )}
    </div>
  );
}
