import React, { useMemo, useState } from 'react';
import { useGenerateModal } from '../../../components/Modal/Modal';
import { FormBuilder } from '../../../domain/FormBuilder/FormBuilder';
import { useTranslation } from 'react-i18next';
import { useEntitiesContext } from '../../../contexts/EntitiesContext';
import Banner from '../../../components/Banner/Banner';

const TRANSLATION_PREFIX = 'entitiesManagementPage.walletDetails.';

function useEditDidAlias() {
  const { didRepo, setSelectedWallet, selectedWallet } = useEntitiesContext();
  const [didToEdit, setDidToEdit] = useState<{
    alias: string;
    did: string;
    walletId: string;
  }>({
    alias: '',
    did: '',
    walletId: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const {
    EditDidAliasModal,
    isEditDidAliasModalOpened,
    setIsEditDidAliasModalOpened,
  } = useGenerateModal<'EditDidAlias'>('EditDidAlias');
  const initialValue = useMemo(
    () => didToEdit.alias,
    [isEditDidAliasModalOpened],
  );

  async function onClick() {
    if (!selectedWallet) {
      return;
    }
    setIsLoading(true);
    const response = await didRepo.editDidAlias(
      didToEdit.walletId,
      didToEdit.did,
      didToEdit.alias,
    );
    setIsEditDidAliasModalOpened(false);
    setIsLoading(false);
    if ('error' in response) {
      Banner.show(t('errors.editDidAlias'), true);
      return;
    }
    const didsListCopy = [...selectedWallet?.dids];
    const didToEditIndex = didsListCopy.findIndex(
      (did) => did.did === didToEdit.did,
    );
    didsListCopy.splice(didToEditIndex, 1, {
      ...didsListCopy[didToEditIndex],
      alias: didToEdit.alias,
    });
    setSelectedWallet({ ...selectedWallet, dids: didsListCopy });
    Banner.show(t('successes.editDidAlias'));
  }

  const editAliasModalJSX = useMemo(() => {
    const formBuilder = new FormBuilder();
    formBuilder.addInput('text', didToEdit.alias, 'alias', (event) =>
      setDidToEdit({ ...didToEdit, alias: event.target.value }),
    );
    formBuilder.addButton(t('actions.save'), onClick, {
      isDisabled: initialValue === didToEdit.alias,
      isLoading,
    });
    return (
      <EditDidAliasModal title={t(`${TRANSLATION_PREFIX}editDidAlias`)}>
        {formBuilder.getJSX()}
      </EditDidAliasModal>
    );
  }, [didToEdit, isEditDidAliasModalOpened, isLoading]);

  return {
    setIsEditDidAliasModalOpened,
    setDidToEdit,
    editAliasModalJSX,
  };
}

export default useEditDidAlias;
