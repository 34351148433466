import React, { useContext, useEffect, useState } from 'react';
import { getCredentialRequest } from '../../../services/vidcredentials';
import { CredentialRequestsListView } from '../../../domain/credentialView';
import { CredentialRequestDetailsModal } from '../CredentialRequestDetailsModal/CredentialRequestDetailsModal';
import { CredentialRequestDetailsView } from '../../../domain/credentialRequestView';
import { CredentialRequestsContext } from '../../../contexts/CredentialsProviders/Providers/CredentialRequestsContext';
import { formatDate } from '../../Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { useTranslation } from 'react-i18next';
import PaginatedTable from '../../../components/TableComponents/PaginatedTable';
import ActionsCell, {
  Action,
} from '../../../components/TableComponents/ActionsCell';
import EyeIcon from '../../../assets/icons/table-icons/eye.svg';

export function CredentialRequestsTable() {
  const [credential, setCredential] = useState(
    {} as CredentialRequestDetailsView,
  );
  const [isCredentialDetailsModalOpen, setCredentialDetailsModalOpen] =
    useState(false);
  const { t } = useTranslation();
  const { loadCredentials, credentials, tableSpecs, isLoading, filters } =
    useContext(CredentialRequestsContext);

  useEffect(() => {
    if (!isCredentialDetailsModalOpen && credential.id) loadCredentials(10, 1);
  }, [isCredentialDetailsModalOpen]);

  const openCredential = async (id: string, e: any) => {
    e.preventDefault();
    try {
      const credential = await getCredentialRequest(id);
      setCredential(credential as CredentialRequestDetailsView);
      setCredentialDetailsModalOpen(true);
    } catch (error) {
      console.log('Error unable to get the credentialDetails');
    }
  };

  const tableColumns = [
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: t('filters.credentialType'),
      field: 'did',
      render: (data: CredentialRequestsListView) =>
        data?.types.map(
          (element, i) =>
            `${element}${i === data.types.length - 1 ? '' : ', '}`,
        ),
    },
    {
      title: t('table.creationDate'),
      field: 'requestedAt',
      render: (data: CredentialRequestsListView) =>
        formatDate(data.requestedAt),
    },
    {
      title: t('table.status'),
      field: 'status',
      render: (data: CredentialRequestsListView) =>
        t(`requestStatus.${data.status}`),
    },
    {
      title: '',
      field: '',
      render: (data: CredentialRequestsListView) => {
        const actions: Action[] = [
          {
            onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
              openCredential(data.id, e),
            labelId: 'detail' + data.id,
            labelTitle: t('table.viewDetails'),
            icon: EyeIcon,
          },
        ];
        return (
          <ActionsCell
            key={`credential-request-actions-${data.id}`}
            actions={actions}
          />
        );
      },
    },
  ];

  return (
    <>
      <CredentialRequestDetailsModal
        isOpen={isCredentialDetailsModalOpen}
        setIsOpen={setCredentialDetailsModalOpen}
        credential={credential}
      />
      <PaginatedTable
        tableSpecs={tableSpecs}
        columns={tableColumns}
        data={credentials}
        isLoading={isLoading}
        filters={filters}
        paginationLoader={loadCredentials}
      />
    </>
  );
}
