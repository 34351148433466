import './Stepper.css';
import React, { useState } from 'react';
import ThemedButton from '../Buttons/ThemedButton';
import { useTranslation } from 'react-i18next';

export type Step = {
  title: string;
  renderStep: () => JSX.Element;
  onNextCLicked?: () => boolean;
  onBackCLicked?: () => boolean;
  isNextDisabled?: boolean;
  isBackDisabled?: boolean;
};

interface StepperProps {
  steps: Step[];
}

function Stepper({ steps }: StepperProps) {
  const { t } = useTranslation();
  const [stepNumber, setStepNumber] = useState<number>(0);
  const {
    renderStep,
    onNextCLicked,
    onBackCLicked,
    isNextDisabled,
    isBackDisabled,
  } = steps[stepNumber];

  return (
    <section className="stepper-container">
      <section className="stepper-container_top-section">
        {steps.map((step, index) => {
          const isDone = index <= stepNumber;
          const isSelected = index === stepNumber;
          return (
            <section
              key={`step-${index}`}
              className="stepper-container_top-section__step-tracker"
            >
              <h3
                className="step-tracker_number"
                style={{
                  backgroundColor: `var(--${
                    isDone ? 'primary-light' : 'alt-light-gray'
                  })`,
                  color: `var(--${isDone ? 'primary' : 'black'})`,
                }}
              >
                {index + 1}
              </h3>
              <h3
                className="step-tracker_title"
                style={{
                  fontFamily: isSelected ? 'TT-Norms' : 'TT-Norms-Regular',
                  fontWeight: isSelected ? 700 : 100,
                }}
              >
                {step.title}
              </h3>
              {index !== steps.length - 1 && (
                <div
                  className="step-tracker_circle__join-line"
                  style={{
                    backgroundPosition:
                      index + 1 <= stepNumber ? 'left' : 'right',
                  }}
                />
              )}
            </section>
          );
        })}
      </section>
      <section className="stepper-container_middle-section">
        {renderStep()}
      </section>
      <section className="stepper-container_bottom-section">
        <ThemedButton
          text={t('actions.back')}
          isDisabled={stepNumber === 0 || isBackDisabled}
          onClick={() => {
            const canGoBack = onBackCLicked && onBackCLicked();
            canGoBack !== false && setStepNumber(stepNumber - 1);
          }}
          background="gray"
        />
        <ThemedButton
          isDisabled={isNextDisabled}
          text={t('details.next')}
          onClick={() => {
            if (stepNumber === steps.length - 1) {
              onNextCLicked && onNextCLicked();
              return;
            }
            const canAdvance = onNextCLicked && onNextCLicked();
            canAdvance !== false && setStepNumber(stepNumber + 1);
          }}
        />
      </section>
    </section>
  );
}

export default Stepper;
