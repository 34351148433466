import './List.css';
import React, { useContext, useMemo } from 'react';
import { Column } from 'material-table';
import TableHeader from '../TableComponents/TableHeader';
import Row from '../TableComponents/Row';
import useScreenDimensions from '../../services/useScreenDimensions';
import computeTableWidth from '../TableComponents/computeTableWidth';
import { MenuContext } from '../../contexts/MenuContext';
import { Spinner } from '../Spinners/CircularSpinner';

interface ListProps {
  title: string;
  emptyMessage: string;
  columns: Partial<Column<any>>[];
  rows: Record<string, any>[];
  isLoading: boolean;
  highlightElement?: (row: Record<string, any>, index: number) => boolean;
}

function List({
  title,
  columns,
  rows,
  emptyMessage,
  isLoading,
  highlightElement,
}: ListProps) {
  const { isMenuOpen } = useContext(MenuContext);
  const { height, width } = useScreenDimensions();
  const headerWidth = useMemo(
    () => computeTableWidth(width, isMenuOpen),
    [width, isMenuOpen],
  );

  function selectComponent() {
    if (isLoading) {
      return (
        <section
          style={{ width: headerWidth }}
          className="list-loading-wrapper"
        >
          <Spinner />
        </section>
      );
    }
    if (!rows.length) {
      return (
        <h3 style={{ width: headerWidth }} className="list-empty-header">
          {emptyMessage}
        </h3>
      );
    }
    return (
      <>
        <TableHeader columns={columns} />
        <ul
          className="list"
          style={{ height: height * (height > 1100 ? 0.7 : 0.5) }}
        >
          {rows.map((row, index) => (
            <Row
              key={`list-item-${title}-${index}`}
              columns={columns}
              data={row}
              isHighlighted={
                highlightElement ? highlightElement(row, index) : undefined
              }
            />
          ))}
        </ul>
      </>
    );
  }

  return (
    <section className="list-wrapper">
      <h2 className="list-header">{title}</h2>
      {selectComponent()}
    </section>
  );
}

export default List;
