import './CallbackHeader.css';
import React from 'react';
import logo from '../../assets/logo-validated.png';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

export const CallbackHeader = () => {
  return (
    <nav className="loginHeader">
      <img src={logo} alt="Logo" />
      <LanguageSelector />
    </nav>
  );
};
