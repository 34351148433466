import { FRONTEND_PATHS } from '../config';

const UUID_REGEX =
  /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i;
const SLASH_SEPARATED_REGEX = /(?<=\/)[^/]+/g;

class UrlPathHandler {
  constructor(private pathName: string) {}
  static isUuid(pathName: string) {
    return UUID_REGEX.test(pathName);
  }
  static mapToFrontendPath(
    path: string,
    entityId?: string,
    walletId?: string,
  ): string {
    if (UrlPathHandler.isUuid(path) && entityId) {
      return walletId
        ? FRONTEND_PATHS.WALLET_DETAILS.replace(':entityId', entityId).replace(
            ':walletId',
            walletId,
          )
        : FRONTEND_PATHS.ENTITY_DETAILS.replace(':entityId', entityId);
    }
    const matchedPath = Object.values(FRONTEND_PATHS).find((frontendPath) =>
      frontendPath.includes(path),
    );
    if (!matchedPath) {
      return '';
    }
    return matchedPath
      .replace(':entityId', entityId ?? '')
      .replace(':walletId', walletId ?? '');
  }
  getTitleKey() {
    const paths = this.pathName.match(SLASH_SEPARATED_REGEX);
    if (!paths) {
      return '';
    }

    let lastPath = paths.pop();
    while (lastPath) {
      if (UrlPathHandler.isUuid(lastPath)) {
        lastPath = paths.pop();
        continue;
      }
      return this.pathToCamelCase(lastPath);
    }
  }
  getPaths(): { key: string; path: string }[] {
    const paths = this.pathName.match(SLASH_SEPARATED_REGEX);
    if (!paths) {
      return [];
    }
    return paths.map((path) => ({
      path,
      key: this.pathToCamelCase(path),
    }));
  }
  private pathToCamelCase(path: string) {
    if (UrlPathHandler.isUuid(path)) {
      return path;
    }
    return path
      .split('-')
      .map((word, index) =>
        index === 0 ? word : `${word.charAt(0).toUpperCase()}${word.slice(1)}`,
      )
      .join('');
  }
}

export default UrlPathHandler;
