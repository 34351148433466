import React, { useContext } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import './CredentialStudioTemplate.css';
import {
  FieldErrors,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';
import { Entity } from '../../domain/entitiesManagementDtos';
import { AuthContext } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';

interface Template {
  name: string;
  fieldType: string;
}

interface Props {
  credentialTypeId: string;
  template: Template[];
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors;
  entities: Entity[];
  setEntitySelected: any;
}

export const CredentialStudioTemplate = (props: Props) => {
  const { t } = useTranslation();
  const { errors, register } = props;
  const { isSuperAdmin } = useContext(AuthContext);

  return (
    <FormGroup>
      <Label for="credentialSubject">{t('form.credentialSubject')}</Label>
      {React.Children.toArray(
        props.template.map((field) => {
          const registerOptions: RegisterOptions = {
            required: t('form.fillfield'),
            pattern:
              field.name === 'email'
                ? {
                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: t('form.provideValidEmail'),
                  }
                : undefined,
          };

          const { ref, ...rest } = register(field.name, registerOptions);

          return (
            <>
              {field.name === 'entity' && (
                <Input
                  type="select"
                  key={field.name}
                  className="studioTemplateField"
                  id={`${props.credentialTypeId}-${field.name}`}
                  placeholder={field.name}
                  innerRef={ref}
                  {...rest}
                  onChange={(event) => {
                    props.setEntitySelected(event.target.value);
                  }}
                >
                  {props.entities.map((entity) => (
                    <option key={entity.id} value={entity.name}>
                      {entity.name}
                    </option>
                  ))}
                </Input>
              )}
              {field.name === 'role' && (
                <Input
                  type="select"
                  key={field.name}
                  className="studioTemplateField"
                  id={`${props.credentialTypeId}-${field.name}`}
                  placeholder={field.name}
                  innerRef={ref}
                  {...rest}
                >
                  <>
                    {isSuperAdmin && (
                      <option key="admin" value="admin">
                        Admin
                      </option>
                    )}
                    <option key="operator" value="operator">
                      Operator
                    </option>
                  </>
                </Input>
              )}
              {field.name === 'name' && (
                <Input
                  type="text"
                  key={field.name}
                  className="studioTemplateField"
                  id={`${props.credentialTypeId}-${field.name}`}
                  placeholder={field.name}
                  innerRef={ref}
                  {...rest}
                />
              )}
              {errors[field.name] && (
                <span className="error-span">
                  {errors[field.name]?.message}
                </span>
              )}
              {field.name === 'legalId' && (
                <Input
                  type="text"
                  key={field.name}
                  className="studioTemplateField"
                  id={`${props.credentialTypeId}-${field.name}`}
                  placeholder={field.name}
                  innerRef={ref}
                  {...rest}
                />
              )}
              {errors[field.name] && (
                <span className="error-span">
                  {errors[field.name]?.message}
                </span>
              )}
            </>
          );
        }),
      )}
    </FormGroup>
  );
};
