import React, { useContext, useState } from 'react';
import {
  getUserCredential,
  ParsedBackendError,
  resendEmailUserCredentialOrder,
  revokeUserVerifiableCredential,
} from '../../../services/vidcredentials';
import {
  CredentialDetailsView,
  CredentialListView,
  UserCredentialListView,
} from '../../../domain/credentialView';
import EyeIcon from '../../../assets/icons/table-icons/eye.svg';
import RevokeIcon from '../../../assets/icons/table-icons/close.png';
import ResendIcon from '../../../assets/icons/table-icons/resend.svg';
import TooltipItem from './../../../components/ToolTipItem/ToolTipItem';
import { Popup } from '../../../components/Popup/Popup';
import { AuthContext } from '../../../contexts/AuthContext';
import { CredentialDetailsModal } from '../../Credentials/CredentialDetailsModal/CredentialDetailsModal';
import { formatDate } from '../../Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { UserCredentialsContext } from '../../../contexts/CredentialsProviders/Providers/UserCredentialsContext';
import { useTranslation } from 'react-i18next';
import PaginatedTable from '../../../components/TableComponents/PaginatedTable';
import ActionsCell, {
  Action,
} from '../../../components/TableComponents/ActionsCell';

export function UserCredentialsTable() {
  const { t } = useTranslation();
  const [isCredentialDetailsModalOpen, setCredentialDetailsModalOpen] =
    useState(false);
  const [projection, setProjection] = useState({} as CredentialListView);
  const [credential, setCredential] = useState({} as CredentialDetailsView);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [revocationPath, setRevocationPath] = useState<string | null>(null);
  const { tableSpecs, loadCredentials, credentials, isLoading, filters } =
    useContext(UserCredentialsContext);

  const { entityName, ...filtersWithoutEntityName } = filters;
  const { isSuperAdmin } = useContext(AuthContext);

  const openCredential = async (data: UserCredentialListView, e: any) => {
    e.preventDefault(); //Prevent the default href=# behavoir
    try {
      const credential = await getUserCredential(data.detailsURL);
      setCredential(credential as CredentialDetailsView);
      setProjection(data);
      setCredentialDetailsModalOpen(true);
    } catch (error) {
      console.log('Error unable to get the credentialDetails');
    }
  };
  const revokeCredential = async (revocationPath: string) => {
    try {
      await revokeUserVerifiableCredential(revocationPath);
      setTimeout(
        () => loadCredentials(tableSpecs.tableSize, tableSpecs.currentPage),
        5000,
      );
      setSuccessMessage(t('details.revokeSuccess'));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage((error as ParsedBackendError).title);
      setSuccessMessage('');
    }
    setRevocationPath(null);
  };

  const resendEmail = async (resendEmailPath: string, e: any) => {
    try {
      e.preventDefault();
      await resendEmailUserCredentialOrder(resendEmailPath);
      setSuccessMessage(t('details.emailResentSuccess'));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage((error as ParsedBackendError).title);
      setSuccessMessage('');
    }
  };

  const tableColumns = [
    {
      title: 'Email',
      field: 'email',
    },

    {
      title: 'DID',
      field: 'did',
      render: (data: UserCredentialListView) => {
        return data.status === 'ISSUED' ? (
          <TooltipItem id={`did-${data.issuanceId}`} title={data.did ?? ''}>
            <h3>{data.did}</h3>
          </TooltipItem>
        ) : (
          t('table.notAvailable')
        );
      },
    },
    {
      title: t('table.creationDate'),
      field: 'createdAt',
      render: (data: UserCredentialListView) => formatDate(data.creationDate),
    },
    {
      title: t('table.status'),
      field: 'status',
      render: (data: UserCredentialListView) => t(`status.${data.status}`),
    },
    {
      title: t('details.role'),
      field: 'role',
      render: (data: UserCredentialListView) => data.userRole,
    },
    {
      title: 'Entity',
      field: 'entity',
      hidden: !isSuperAdmin,
      render: (data: UserCredentialListView) => data.userEntityName,
    },
    {
      title: '',
      field: '',
      render: (data: UserCredentialListView) => {
        const actions: Action[] = [
          {
            onClick: () => setRevocationPath(data.revokeURL || null),
            labelId: 'revoke' + data.issuanceId,
            labelTitle: t('table.revokeCredential'),
            icon: RevokeIcon,
            isDisabled: !data.revokeURL,
          },
          {
            onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
              resendEmail(data.resendURL as string, e),
            labelId: 'resend' + data.issuanceId,
            labelTitle: t('table.resendEmail'),
            icon: ResendIcon,
            isDisabled: data.status !== 'VERIFIED',
          },
          {
            onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
              openCredential(data, e),
            labelId: 'detail' + data.issuanceId,
            labelTitle: t('table.viewDetails'),
            icon: EyeIcon,
          },
        ];
        return (
          <ActionsCell
            key={`user-credential-actions-${data.issuanceId}`}
            actions={actions}
          />
        );
      },
    },
  ];

  return (
    <div>
      <CredentialDetailsModal
        isOpen={isCredentialDetailsModalOpen}
        setIsOpen={setCredentialDetailsModalOpen}
        credential={credential}
        projection={projection}
      />
      <PaginatedTable
        tableSpecs={tableSpecs}
        columns={tableColumns}
        data={credentials}
        isLoading={isLoading}
        filters={filtersWithoutEntityName}
        paginationLoader={loadCredentials}
      />
      {errorMessage && (
        <Popup
          error
          message={errorMessage}
          onClose={() => setErrorMessage('')}
        />
      )}
      {successMessage && (
        <Popup
          success
          message={successMessage}
          onClose={() => setSuccessMessage('')}
        />
      )}
      {revocationPath && (
        <Popup
          confirmation
          message={t('table.sureRevoke')}
          onConfirm={() => revokeCredential(revocationPath)}
          onReject={() => setRevocationPath(null)}
          onClose={() => setRevocationPath(null)}
        />
      )}
    </div>
  );
}
