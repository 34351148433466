import './Spinner.css';
import React, { useState } from 'react';
import VIDSpinnerGif from '../../assets/spinners/vid-spinner.gif';

function VIDSpinner() {
  const [isDisplayed, setIsDisplayed] = useState(false);

  function show() {
    setIsDisplayed(true);
  }
  function hide() {
    setIsDisplayed(false);
  }

  VIDSpinner.show = show;
  VIDSpinner.hide = hide;

  if (!isDisplayed) {
    return null;
  }

  return (
    <main className="spinner-wrapper">
      <img src={VIDSpinnerGif} alt="Validated ID loading spinner" />
    </main>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace VIDSpinner {
  let show: () => void;
  let hide: () => void;
}

export default VIDSpinner;
