import axiosInstance from './axiosInstance';
import { BACKEND_URL, PATHS } from '../config';
import {
  CreateWalletRequestBody,
  Wallet,
} from '../domain/entitiesManagementDtos';
import { v4 as uuidv4 } from 'uuid';

class WalletRepository {
  async getWallets(
    entityId: string,
  ): Promise<{ data: Wallet[] } | { error: string }> {
    const dynamicPath = PATHS.GET_WALLETS.replace(':entityId', entityId);
    const response = await axiosInstance.get(`${BACKEND_URL}${dynamicPath}`);
    if (response.status >= 400) {
      return { error: response.statusText };
    }
    return { data: response.data };
  }
  async createWallet(
    createWalletRequestBody: CreateWalletRequestBody,
  ): Promise<{ walletId: string } | { error: string }> {
    const walletId = uuidv4();
    const response = await axiosInstance.post(
      `${BACKEND_URL}${PATHS.WALLETS}`,
      { ...createWalletRequestBody, walletId },
    );
    if (response.status >= 400) {
      return { error: response.statusText };
    }
    return { walletId };
  }
  async deleteWallet(
    entityId: string,
    walletId: string,
  ): Promise<{ status: string } | { error: string }> {
    const dynamicPath = PATHS.DELETE_WALLET.replace(
      ':entityId',
      entityId,
    ).replace(':walletId', walletId);
    const response = await axiosInstance.delete(`${BACKEND_URL}${dynamicPath}`);
    if (response.status >= 400) {
      return { error: response.statusText };
    }
    return { status: 'OK' };
  }
  async editAlias(
    walletId: string,
    alias: string,
  ): Promise<{ status: string } | { error: string }> {
    const response = await axiosInstance.patch(
      `${BACKEND_URL}${PATHS.WALLETS}/${walletId}`,
      { alias },
    );
    if (response.status >= 400) {
      return { error: response.statusText };
    }
    return { status: 'OK' };
  }
}

export default WalletRepository;
