import './CreateDidSuccess.css';
import React, { useState } from 'react';
import {
  CreateDidRequestBody,
  DidMethods,
} from '../../../domain/entitiesManagementDtos';
import { useTranslation } from 'react-i18next';
import ThemedButton from '../../../components/Buttons/ThemedButton';
import DownloadIcon from '../../../assets/icons/download.svg';
import { useEntitiesContext } from '../../../contexts/EntitiesContext';
import Banner from '../../../components/Banner/Banner';
import { useNavigate } from 'react-router-dom';
import { FRONTEND_PATHS } from '../../../config';

interface CreateDidSuccessProps {
  did: CreateDidRequestBody;
  entityId: string;
  walletId: string;
}

function CreateDidSuccess({ did, entityId, walletId }: CreateDidSuccessProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { didRepo } = useEntitiesContext();

  async function onDownload() {
    if (did.method !== DidMethods.web || !did.opts?.url) {
      return;
    }
    setIsLoading(true);
    try {
      const urlDomain = new URL(did.opts.url).hostname.replace('www.', '');
      const response = await didRepo.getDidDocument(`did:web:${urlDomain}`);
      setIsLoading(false);
      if ('error' in response) {
        Banner.show(t('errors.didDocument'), true);
        return;
      }
      const jsonBlob = new Blob([JSON.stringify(response.data, null, 2)], {
        type: 'application/json',
      });
      const linkToDownload = document.createElement('a');
      linkToDownload.href = URL.createObjectURL(jsonBlob);
      linkToDownload.download = `did-web.json`;
      linkToDownload.click();
      linkToDownload.remove();
    } catch (error) {
      setIsLoading(false);
      Banner.show(t('errors.didDocument'), true);
    }
  }

  return (
    <section className="create-success-container">
      <section className="create-success-container_description-container">
        <p>
          {t(
            `entitiesManagementPage.createDid.${
              did.method === DidMethods.web ? 'didWebMessage' : 'success'
            }`,
          )}
        </p>
        {did.method === DidMethods.web && (
          <ThemedButton
            text={t('actions.download')}
            onClick={onDownload}
            size="small"
            background="gray"
            icon={DownloadIcon}
            isLoading={isLoading}
          />
        )}
      </section>
      <ThemedButton
        text={t('actions.continue')}
        onClick={() =>
          navigate(
            `${
              FRONTEND_PATHS.ENTITIES_MANAGEMENT
            }${FRONTEND_PATHS.WALLET_DETAILS.replace(
              ':entityId',
              entityId,
            ).replace(':walletId', walletId)}`,
          )
        }
      />
    </section>
  );
}

export default CreateDidSuccess;
