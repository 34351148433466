import axios from 'axios';

const accessToken = localStorage.getItem('accessToken');
const axiosInstance = axios.create({
  withCredentials: true,
});

axiosInstance.defaults.headers.common[
  'Authorization'
] = `Bearer ${accessToken}`;
// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (result) {
    return result.response;
  },
);

export default axiosInstance;
