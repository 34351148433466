import React, { useEffect, useState } from 'react';
import { CreateWalletRequestBody } from '../../domain/entitiesManagementDtos';
import { useEntitiesContext } from '../../contexts/EntitiesContext';
import { useTranslation } from 'react-i18next';
import { SelectInputOption } from '../../domain/FormBuilder/FormBuilder';
import { Spinner } from '../Spinners/CircularSpinner';
import ThemedInput from '../ThemedInput/ThemedInput';

interface SecondStepProps {
  keyStorageId: string;
  setWallet: React.Dispatch<React.SetStateAction<CreateWalletRequestBody>>;
  setKeyStorageName: React.Dispatch<React.SetStateAction<string>>;
}

function SecondStep({
  keyStorageId,
  setWallet,
  setKeyStorageName,
}: SecondStepProps) {
  const { entityRepo } = useEntitiesContext();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [keyStorageOptions, setKeyStorageOptions] = useState<
    SelectInputOption[]
  >([]);

  useEffect(() => {
    entityRepo.getKeyStorages().then((response) => {
      if ('error' in response) {
        setIsError(true);
        return;
      }
      const options: SelectInputOption[] = response.data.map(
        ({ keyStorageId, type }) => ({ name: type, value: keyStorageId }),
      );
      setKeyStorageOptions(options);
      setIsLoading(false);
    });
  }, []);

  if (isError) {
    return <p>{t('errors.getKeyStorages')}</p>;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <ThemedInput
      type="select"
      value={keyStorageId}
      name="keyStorage"
      inputOptions={{
        label: 'Key Storage',
        description: t(
          'entitiesManagementPage.createWallet.keyStorageDescription',
        ),
        selectInputOptions: keyStorageOptions,
        onSelectOption: (option) => {
          setKeyStorageName(option.name);
          setWallet((prevState) => ({
            ...prevState,
            keyStorageId: option.value,
          }));
        },
      }}
    />
  );
}

export default SecondStep;
