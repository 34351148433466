import { useContext, useEffect, useMemo, useState } from 'react';
import { convertToQueryString } from '../../../pages/Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { FormBuilder, SelectInputOption } from '../FormBuilder';
import { Entity } from '../../entitiesManagementDtos';
import { AuthContext } from '../../../contexts/AuthContext';
import { getEntities } from '../../../services/entities';
import {
  UserCredentialsContextType,
  UserCredentialsFilters,
} from '../../../contexts/CredentialsProviders/Providers/UserCredentialsContext';
import { placeholders, statusOptions } from './buildsConstants';
import { useTranslation } from 'react-i18next';

interface UserCredentialsFilterFormProps {
  context: UserCredentialsContextType;
  closeModal: () => void;
}

function UserCredentialsFilterForm({
  context,
  closeModal,
}: UserCredentialsFilterFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [entities, setEntities] = useState<SelectInputOption[]>([
    { name: t('filters.selectEntity'), value: '' },
  ]);
  const { filters, setFilters, loadCredentials, tableSpecs } =
    useContext(context);
  const cachedFilterState = useMemo(() => filters, []);
  const { isSuperAdmin } = useContext(AuthContext);

  const handleClose = () => {
    setFilters(cachedFilterState);
    closeModal();
  };

  const applyFilter = async () => {
    setIsLoading(true);
    const { entityName, ...filtersWithoutEntityName } = filters;
    const queryParams = convertToQueryString(filtersWithoutEntityName);
    await loadCredentials(tableSpecs.tableSize, 1, queryParams, true);
    setIsLoading(false);
    closeModal();
  };

  const handleChange = (event: { target: HTMLInputElement }) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const fetchEntities = async () => {
    if (!isSuperAdmin) {
      return;
    }
    const entitiesResponse: Entity[] = await getEntities();
    const entitiesOptions = [
      entities[0],
      ...entitiesResponse.map(({ id, name }) => ({ value: id, name })),
    ];
    setEntities(entitiesOptions);
  };

  useEffect(() => {
    fetchEntities();
  }, []);

  return useMemo(() => {
    const formBuilder = new FormBuilder();
    const { status, entityId, entityName, ...textInputFilters } = filters;
    formBuilder.addInput('select', filters.status, 'status', () => null, {
      selectInputOptions: statusOptions,
      placeholder: statusOptions[0].name,
      initialSelectedOption: statusOptions.find(
        (statusOption) => statusOption.value === cachedFilterState.status,
      ),
      onSelectOption: ({ value }) => setFilters({ ...filters, status: value }),
    });
    isSuperAdmin &&
      formBuilder.addInput('select', filters.entityId, 'entityId', () => null, {
        selectInputOptions: entities,
        placeholder: entities[0].name,
        initialSelectedOption: {
          name: cachedFilterState.entityName,
          value: cachedFilterState.entityId,
        },
        onSelectOption: ({ name, value }) =>
          setFilters({
            ...filters,
            entityId: value,
            entityName: value ? name : '',
          }),
      });
    Object.keys(textInputFilters).map((key, _) => {
      formBuilder.addInput(
        'text',
        filters[key as keyof UserCredentialsFilters],
        key,
        handleChange,
        {
          placeholder: placeholders[key],
        },
      );
    });
    formBuilder.addButton(t('actions.cancel'), handleClose, {
      background: 'gray',
    });
    formBuilder.addButton(t('actions.filter'), applyFilter, { isLoading });
    return formBuilder.getJSX();
  }, [filters, entities, isLoading]);
}

export default UserCredentialsFilterForm;
