import React, { useEffect, useState } from 'react';
import './Callback.css';
import background from '../../assets/background.svg';
import { Container } from 'reactstrap';
import { CallbackHeader } from '../../components/CallbackHeader/CallbackHeader';
import {
  issueCredential,
  ParsedBackendError,
} from '../../services/vidcredentials';
import { SuccessMessage } from '../../components/SuccessMessage/SuccessMessage';
import { isMobile } from '../../services/mobileDetector';
import { useTranslation } from 'react-i18next';
import { DEMO_URL } from '../../config';

export function Callback() {
  const { t } = useTranslation();
  const [hasBeenSuccess, setHasBeenSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTitle, setErrorTitle] = useState('');

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get('code');
    if (!code) {
      console.log('Error credential request id not found');
      setHasBeenSuccess(false);
      return;
    }

    const automaticallyIssueCredential = async () => {
      const credentialRequestId = localStorage.getItem('credentialRequestId');
      if (!credentialRequestId) {
        console.log('Error credential request id not found');
        setHasBeenSuccess(false);
        return;
      }

      const response = await issueCredential(credentialRequestId, code ?? '');
      if ('error' in response) {
        setErrorMessage(`${(response.error as ParsedBackendError).details}`);
        setErrorTitle(`${(response.error as ParsedBackendError).title}`);
        setHasBeenSuccess(false);
        return;
      }

      localStorage.setItem('successIssuedCredentialCode', code);
    };

    const previousCode = localStorage.getItem('successIssuedCredentialCode');

    if (code === previousCode) {
      setHasBeenSuccess(true);
      return;
    }

    automaticallyIssueCredential();
    setHasBeenSuccess(true);
  }, []);

  const isUsingMobile = isMobile();

  return (
    <>
      <Container>
        <CallbackHeader />
        <section className="home-container">
          <div className="info-column">
            <h6>By Validated ID</h6>
            {hasBeenSuccess && (
              <SuccessMessage
                messageTitle={t('callback.mobile_wallet')}
                message={''}
              />
            )}
            {!hasBeenSuccess && (
              <>
                <h1>{t('callback.failed')}</h1>
                <p id="">{errorTitle}</p>
                <p id="errorMessage">{t('callback.error_details')}</p>
                <p id="errorMessage">
                  <i>{errorMessage}</i>
                </p>
              </>
            )}
            <div className="vid-chain-buttons-container">
              <a
                className="sign-in-vid-chain"
                href={isUsingMobile ? 'vidchain://notifications' : DEMO_URL}
              >
                <span>
                  {isUsingMobile
                    ? t('callback.open_wallet')
                    : t('login.more_info')}
                </span>
                <span>&rarr;</span>
              </a>
            </div>
          </div>
          <div className="background-column">
            <img src={background} alt="Background" />
          </div>
        </section>
      </Container>
    </>
  );
}
