import '../Shared.css';
import React, { useContext } from 'react';
import { AuditTrailsTable } from './AuditTrailsTable/AuditTrailsTable';
import FilterTag from '../../components/FilterTag/FilterTag';
import { useRouteSecurization } from '../../hooks/useRouteSecurization';
import { AuditTrailsContext } from '../../contexts/CredentialsProviders/Providers/AuditTrailsContext';
import TableActions from '../../components/TableComponents/TableActions';
import { UserCredentialsFilters } from '../../contexts/CredentialsProviders/Providers/UserCredentialsContext';
import {
  convertToQueryString,
  formatDate,
} from '../Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { useTranslation } from 'react-i18next';

export const AuditTrails = () => {
  useRouteSecurization(['admin', 'superadmin']);
  const { t } = useTranslation();
  const TAGS_NAMES: Record<string, string> = {
    entityName: t('details.entity'),
    searchableText: t('filters.searchText'),
    action: t('filters.actionName'),
    startDate: t('filters.startDate'),
    endDate: t('filters.endDate'),
    issuanceId: t('details.credentialIssuanceId'),
  };
  const { filters, setFilters, loadCredentials, tableSpecs } =
    useContext(AuditTrailsContext);

  const onRemoveTag = async (filterKey: keyof UserCredentialsFilters) => {
    const updatedFilter = {
      ...filters,
      [filterKey]: '',
      entityId: filterKey === 'entityName' ? '' : filters.entityId,
    };
    const queryParams = convertToQueryString(updatedFilter);
    setFilters(updatedFilter);
    await loadCredentials(tableSpecs.tableSize, 1, queryParams);
  };

  return (
    <div className="credentials-container">
      <TableActions context={AuditTrailsContext} />
      <div className="filter-list-container">
        {Object.entries(filters).map(([key, value]) => {
          if (key === 'entityId') return null;
          const tagValue = key.includes('Date') ? formatDate(value) : value;
          return (
            value && (
              <FilterTag
                key={key}
                filter={`${TAGS_NAMES[key]}: ${tagValue}`}
                onClose={() => onRemoveTag(key as keyof UserCredentialsFilters)}
              />
            )
          );
        })}
      </div>
      <AuditTrailsTable />
    </div>
  );
};
