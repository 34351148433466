import './WalletDetails.css';
import React, { useCallback, useEffect, useState } from 'react';
import { useGenerateModal } from '../../../components/Modal/Modal';
import ReactJson from 'react-json-view';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../components/Spinners/CircularSpinner';
import { useEntitiesContext } from '../../../contexts/EntitiesContext';
import CopyIcon from '../../../assets/icons/copy.svg';
import CheckIcon from '../../../assets/icons/check.svg';
import TooltipItem from '../../../components/ToolTipItem/ToolTipItem';

const TRANSLATION_PREFIX = 'entitiesManagementPage.walletDetails.';

function useDidDetails() {
  const [didDocument, setDidDocument] = useState<Record<string, any>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const { didRepo } = useEntitiesContext();
  const { t } = useTranslation();
  const {
    isDidDetailsModalOpened,
    setIsDidDetailsModalOpened,
    DidDetailsModal,
  } = useGenerateModal<'DidDetails'>('DidDetails');

  async function onOpenDidDetailsModal(did: string) {
    setIsDidDetailsModalOpened(true);
    const response = await didRepo.getDidDocument(did);
    if ('error' in response) {
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    setDidDocument(response.data);
  }

  const selectComponent = useCallback(() => {
    if (isLoading) {
      return <Spinner />;
    }
    if (!didDocument) {
      return <p>{t('errors.didDocument')}</p>;
    }
    return (
      <section className="did-details-container_did-document-section">
        <section className="did-details-container_did-document-section-wrapper">
          <TooltipItem
            title={t(`actions.${isClicked ? 'copied' : 'copy'}`)}
            id="copy-did-document"
          >
            <button
              className="did-details-container_did-document-section__button"
              disabled={isClicked}
              onClick={async () => {
                await navigator.clipboard.writeText(
                  JSON.stringify(didDocument),
                );
                setIsClicked(true);
                setTimeout(() => setIsClicked(false), 3000);
              }}
            >
              <img src={isClicked ? CheckIcon : CopyIcon} alt="Copy icon" />
            </button>
          </TooltipItem>
        </section>
        <ReactJson
          src={didDocument}
          displayDataTypes={false}
          displayObjectSize={false}
          enableClipboard={false}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          displayArrayKey={false}
          collapsed={1}
          theme="grayscale:inverted"
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </section>
    );
  }, [isLoading, didDocument, isDidDetailsModalOpened, isClicked]);

  useEffect(() => {
    !isDidDetailsModalOpened && setIsLoading(true);
    !isDidDetailsModalOpened && setDidDocument(undefined);
  }, [isDidDetailsModalOpened]);

  return {
    onOpenDidDetailsModal,
    DidDetailsModal: () => {
      return (
        <DidDetailsModal title={t(`${TRANSLATION_PREFIX}didDetails`)}>
          <section className="did-details-container">
            {selectComponent()}
          </section>
        </DidDetailsModal>
      );
    },
  };
}

export default useDidDetails;
