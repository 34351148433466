import { Modal, ModalHeader, ModalBody, Table } from 'reactstrap';
import './AuditTrailsDetailsModal.css';
import { AuditTrailDetail } from '../../../domain/auditTrails';
import closeIcon from '../../../assets/icons/table-icons/close.png';
import ReactJson from 'react-json-view';
import { useTranslation } from 'react-i18next';

interface Props {
  auditTrail: AuditTrailDetail;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const AuditTrailsDetailsModal = (props: Props) => {
  const { t } = useTranslation();
  const { auditTrail } = props;
  const closeModal = (e: any) => {
    e.preventDefault();
    props.setIsOpen(false);
  };

  const formatDate = (date: string | undefined): string => {
    if (!date) {
      return t('table.notAvailable');
    }
    const formattedDate = new Date(date);
    return formattedDate.toLocaleString();
  };

  return (
    <Modal isOpen={props.isOpen} centered={true} fullscreen="lg" size="lg">
      <ModalHeader
        close={
          <a href="#" onClick={(e) => closeModal(e)}>
            <img src={closeIcon} className="iconClose" />
          </a>
        }
        toggle={() => props.setIsOpen(false)}
        className="modalHeader"
      >
        {t('details.auditTrailsDetails')}
      </ModalHeader>
      <ModalBody className="modalBody">
        <div className="scroll-div">
          <h4>{t('details.entity')}</h4>
          <Table borderless className="tableModal">
            <tbody>
              <tr>
                <th scope="row">{t('details.name')}:</th>
                <td data-testid="type">
                  {auditTrail.entity?.name || t('table.notAvailable')}
                </td>
              </tr>
              <tr>
                <th scope="row">ID:</th>
                <td data-testid="type">
                  {auditTrail.entity?.id || t('table.notAvailable')}
                </td>
              </tr>
              <tr>
                <th scope="row">DID:</th>
                <td>{auditTrail.entity?.did || t('table.notAvailable')}</td>
              </tr>
            </tbody>
          </Table>
          <h4>{t('details.operator')}</h4>
          <Table borderless className="tableModal">
            <tbody>
              <tr>
                <th scope="row">{t('details.operatorName')}:</th>
                <td data-testid="type">
                  {auditTrail.agent?.name || t('table.notAvailable')}
                </td>
              </tr>
              <tr>
                <th scope="row">{t('details.role')}:</th>
                <td data-testid="type">
                  {auditTrail.agent?.role || t('table.notAvailable')}
                </td>
              </tr>
              <tr>
                <th scope="row">DID:</th>
                <td>{auditTrail.agent?.did || t('table.notAvailable')}</td>
              </tr>
            </tbody>
          </Table>
          <h4>{t('details.action')}</h4>
          <Table borderless className="tableModal">
            <tbody>
              <tr>
                <th scope="row">{t('details.eventName')}:</th>
                <td data-testid="type">
                  {t(`auditTrailsActions.${auditTrail.action?.name}`) ||
                    t('table.notAvailable')}
                </td>
              </tr>
              <tr>
                <th scope="row">{t('details.payload')}:</th>
                <td data-testid="type">
                  <ReactJson
                    src={auditTrail.action?.payload}
                    theme="bright:inverted"
                    style={{ width: '600px', overflowX: 'auto' }}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    enableClipboard={false}
                    name={false}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore */}
                    displayArrayKey={false}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
          {auditTrail.documents && (
            <>
              <h4>{t('details.attachments')}</h4>
              <Table borderless className="tableModal">
                <tbody>
                  {auditTrail.documents.map((document) => (
                    <>
                      <tr>
                        <th>{t('details.name')}:</th>
                        <td>
                          <a
                            download={`${document.name}.${document.type}`}
                            href={`data:application/${document.type};base64,${document.content}`}
                            title={`${document.name}.${document.type}`}
                          >
                            {`${document.name}.${document.type}`}
                          </a>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            </>
          )}
          <h4>{t('table.date')}</h4>
          <Table borderless className="tableModal">
            <tbody>
              <tr>
                <th scope="row">{t('table.date')}:</th>
                <td data-testid="type">{formatDate(auditTrail.date)}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </ModalBody>
    </Modal>
  );
};
