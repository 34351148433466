import React, { createContext, PropsWithChildren, useState } from 'react';

export const MenuContext = createContext<{
  isMenuOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isMenuOpen: true,
  setIsOpen: () => null,
});

export const MenuContextProvider = ({ children }: PropsWithChildren<any>) => {
  const [isMenuOpen, setIsOpen] = useState(true);

  return (
    <MenuContext.Provider
      value={{
        isMenuOpen,
        setIsOpen,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
