import { useContext, useEffect, useMemo, useState } from 'react';
import { convertToQueryString } from '../../../pages/Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { FormBuilder, SelectInputOption } from '../FormBuilder';
import { Entity } from '../../entitiesManagementDtos';
import { AuthContext } from '../../../contexts/AuthContext';
import { getEntities } from '../../../services/entities';
import { ACTION_NAME_OPTIONS } from './buildsConstants';
import { AuditTrailsContextType } from '../../../contexts/CredentialsProviders/Providers/AuditTrailsContext';
import { useTranslation } from 'react-i18next';

interface AuditTrailsFilterFormProps {
  context: AuditTrailsContextType;
  closeModal: () => void;
}

function AuditTrailsFilterForm({
  context,
  closeModal,
}: AuditTrailsFilterFormProps) {
  const { t } = useTranslation();
  const [entities, setEntities] = useState<SelectInputOption[]>([
    { name: t('filters.selectEntity'), value: '' },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const { filters, setFilters, loadCredentials, tableSpecs } =
    useContext(context);
  const cachedFilterState = useMemo(() => filters, []);

  const { isSuperAdmin } = useContext(AuthContext);

  const handleClose = () => {
    setFilters(cachedFilterState);
    closeModal();
  };

  const applyFilter = async () => {
    setIsLoading(true);
    const { entityName, ...filtersWithoutEntityName } = filters;
    const queryParams = convertToQueryString(filtersWithoutEntityName);
    await loadCredentials(tableSpecs.tableSize, 1, queryParams, true);
    setIsLoading(false);
    closeModal();
  };

  const handleChange = (event: { target: HTMLInputElement }) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const fetchEntities = async () => {
    if (!isSuperAdmin) {
      return;
    }
    const entitiesResponse: Entity[] = await getEntities();
    const entitiesOptions = [
      entities[0],
      ...entitiesResponse.map(({ id, name }) => ({
        value: id,
        name,
      })),
    ];
    setEntities(entitiesOptions);
  };

  useEffect(() => {
    fetchEntities();
  }, []);

  return useMemo(() => {
    const formBuilder = new FormBuilder();
    const { action, entityId, issuanceId, startDate, endDate } = filters;
    isSuperAdmin &&
      formBuilder.addInput('select', entityId, 'entityId', () => null, {
        selectInputOptions: entities,
        placeholder: entities[0].name,
        initialSelectedOption: {
          name: cachedFilterState.entityName,
          value: cachedFilterState.entityId,
        },
        onSelectOption: ({ value, name }) =>
          setFilters({
            ...filters,
            entityId: value,
            entityName: value ? name : '',
          }),
      });
    formBuilder.addInput('select', action, 'action', () => null, {
      selectInputOptions: ACTION_NAME_OPTIONS,
      placeholder: ACTION_NAME_OPTIONS[0].name,
      initialSelectedOption: ACTION_NAME_OPTIONS.find(
        (actionNameOption) =>
          actionNameOption.value === cachedFilterState.action,
      ),
      onSelectOption: ({ value, name }) =>
        setFilters({
          ...filters,
          action: value,
        }),
    });
    formBuilder.addInput('text', issuanceId, 'issuanceId', handleChange, {
      placeholder: 'Issuance ID',
    });
    formBuilder.addInput(
      'datetime-local',
      startDate,
      'startDate',
      handleChange,
      { label: t('filters.startDate') },
    );
    formBuilder.addInput('datetime-local', endDate, 'endDate', handleChange, {
      label: t('filters.endDate'),
      min: startDate,
    });
    formBuilder.addButton(t('actions.cancel'), handleClose, {
      background: 'gray',
    });
    formBuilder.addButton(t('actions.filter'), applyFilter, { isLoading });
    return formBuilder.getJSX();
  }, [entities, filters, isLoading]);
}

export default AuditTrailsFilterForm;
