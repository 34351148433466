import './Banner.css';
import React, { useState } from 'react';

const DEFAULT_STATE = { text: '', type: 'success' };

function Banner() {
  const [isShown, setIsShown] = useState(false);
  const [message, setMessage] = useState(DEFAULT_STATE);

  function show(text: string, isError?: true) {
    setIsShown(true);
    setMessage({ text, type: isError ? 'error' : 'success' });
    setTimeout(() => {
      setIsShown(false);
    }, 3000);
  }

  Banner.show = show;

  return (
    <div
      className="banner-container"
      style={{
        zIndex: isShown ? 10 : -10,
        opacity: isShown ? 1 : 0,
        backgroundColor: `var(--${
          message.type === 'error' ? 'error-bg' : 'primary-light'
        }`,
      }}
    >
      <p
        style={{
          color: `var(--${
            message.type === 'error' ? 'error' : 'primary'
          }-font-color`,
        }}
      >
        {message.text}
      </p>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Banner {
  let show: (text: string, isError?: true) => void;
}

export default Banner;
