import './AuditTrailsTable.css';
import React, { useContext, useState } from 'react';
import { getAuditTrailsDetail } from '../../../services/vidcredentials';
import { AuditTrailsDetailsModal } from '../AuditTrailsDetailsModal/AuditTrailsDetailsModal';
import { AuditTrailDetail, AuditTrailsList } from '../../../domain/auditTrails';
import { formatDate } from '../../Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { AuditTrailsContext } from '../../../contexts/CredentialsProviders/Providers/AuditTrailsContext';
import { useTranslation } from 'react-i18next';
import PaginatedTable from '../../../components/TableComponents/PaginatedTable';
import EyeIcon from '../../../assets/icons/table-icons/eye.svg';
import ActionsCell, {
  Action,
} from '../../../components/TableComponents/ActionsCell';

export function AuditTrailsTable() {
  const { t } = useTranslation();
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);
  const {
    credentials: auditTrailsList,
    loadCredentials,
    tableSpecs,
    filters,
    isLoading,
  } = useContext(AuditTrailsContext);
  const [auditTrail, setAuditTrail] = useState<AuditTrailDetail>(
    {} as AuditTrailDetail,
  );
  const { entityName, ...filtersWithoutEntityName } = filters;

  const openAuditTrail = async (id: string) => {
    try {
      setDetailsModalOpen(true);
      const auditTrailResponse = await getAuditTrailsDetail(id);
      setAuditTrail(auditTrailResponse as AuditTrailDetail);
    } catch (error) {
      return;
    }
  };

  const tableColumns = [
    {
      title: t('filters.entityName'),
      field: 'entityName',
      render: (data: AuditTrailsList) => data.entityName,
    },
    {
      title: t('table.agentName'),
      field: 'agent',
      render: (data: AuditTrailsList) =>
        data.operator || t('table.notAvailable'),
    },
    {
      title: t('filters.actionName'),
      field: 'action',
      render: (data: AuditTrailsList) =>
        `${t(`auditTrailsActions.${data.action}`)}`,
    },
    {
      title: t('table.date'),
      field: 'date',
      render: (data: AuditTrailsList) => formatDate(data.date),
    },
    {
      title: '',
      field: '',
      render: (data: AuditTrailsList) => {
        const actions: Action[] = [
          {
            onClick: () => openAuditTrail(data.eventId),
            labelId: 'detail' + data.eventId,
            labelTitle: t('table.viewDetails'),
            icon: EyeIcon,
          },
        ];
        return (
          <ActionsCell
            key={`audit-trails-actions-${data.eventId}`}
            actions={actions}
          />
        );
      },
    },
  ];

  return (
    <>
      <AuditTrailsDetailsModal
        auditTrail={auditTrail}
        isOpen={isDetailsModalOpen}
        setIsOpen={setDetailsModalOpen}
      />
      <PaginatedTable
        tableSpecs={tableSpecs}
        columns={tableColumns}
        data={auditTrailsList}
        isLoading={isLoading}
        filters={filtersWithoutEntityName}
        paginationLoader={loadCredentials}
      />
    </>
  );
}
